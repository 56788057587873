import {
  AbsoluteCenter,
  Card,
  CardHeader,
  Flex,
  Center,
  CardBody,
  Icon,
} from "@chakra-ui/react";
import React from "react";

import { colors } from "../utils/Tools";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { applyFilters } from "../features/allOrders/allOrdersSlice";
import { icons } from "../utils/Tools";
function Results({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { states } = useSelector((store) => store.lang);
  return (
    <Flex
      flexWrap={"wrap"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={".4rem"}
      w={"100%"}
      minH="140px"
      my={"1rem"}
    >
      {data.map((item, index) => {
        return (
          <Card
            cursor={"pointer"}
            onClick={() => {
              dispatch(
                applyFilters({
                  state: index,
                  search: "",
                  startDate: "all",
                  endDate: "all",
                  sortedAsc: false,
                  productId: "all",
                  priority: "all",
                })
              );
              navigate("/all-orders");
            }}
            paddingX={".2rem"}
            key={index}
            bg={colors[index]}
            boxShadow={"md"}
            minW={"10rem"}
            h={"100%"}
          >
            <CardHeader>
              <Center color={"white"} fontWeight={"semibold"} fontSize={"sm"}>
                {states[index].toUpperCase()}
              </Center>
            </CardHeader>

            <CardBody>
              <AbsoluteCenter
                color={"white"}
                fontWeight={"bold"}
                fontSize={"xl"}
              >
                {item.value}
              </AbsoluteCenter>
              <Center color={"white"} mt={"1rem"}>
                {icons.white[index]}
              </Center>
            </CardBody>
          </Card>
        );
      })}
    </Flex>
  );
}

export default Results;
