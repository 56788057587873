import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASEURL } from "../utils/urls";
import { getTheError } from "../utils/ErorrHandling";

const initialState = {
  ad: undefined,
  id: undefined,
  isLoading: false,
};

export const postAd = createAsyncThunk("ad/postAd", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(
      `${BASEURL}/api/announcement/add-announcement`,
      { content: data },
      {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      }
    );
    const { error } = thunkAPI.getState().lang;

    resp.data.msg = error.success;
    return resp.data;
  } catch (error) {
    return getTheError(error, thunkAPI);
  }
});
export const deleteAd = createAsyncThunk(
  "ad/deleteAd",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${BASEURL}/api/announcement/delete-announcement/${data}`,

        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      const { error } = thunkAPI.getState().lang;

      resp.data.msg = error.success;

      return resp.data;
    } catch (error) {
      if (
        !error.response ||
        !error.response.data ||
        !error.response.data.message ||
        error.response.data.code === "A7DF"
      ) {
        return thunkAPI.rejectWithValue(
          thunkAPI.getState().lang.error.serverError
        );
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
const adSlice = createSlice({
  name: "ad",
  initialState,
  reducers: {
    updateAd: (state, { payload }) => {
      state.ad = payload?.content;
      state.id = payload?.id;
    },
    removeAd: (state, { payload }) => {
      state.ad = undefined;
      state.id = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAd.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postAd.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      message.success(payload?.msg);
    });
    builder.addCase(postAd.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(deleteAd.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAd.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      message.success(payload?.msg);
    });
    builder.addCase(deleteAd.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
  },
});
export const { updateAd, removeAd } = adSlice.actions;
export default adSlice.reducer;
