import React, { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Flex } from "@chakra-ui/react";
import { Button, Layout, theme } from "antd";
import { Text } from "@chakra-ui/react";
import NewFilter from "../components/NewFilter";
import { FaTableCells, FaTableList } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { changeViewTo } from "../features/allOrders/allOrdersSlice";

const { Header, Sider, Content } = Layout;
const NewAllOrdersLayout = ({ children, doNothing, component }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const { view } = useSelector((store) => store.orders);
  const { user, accountant } = useSelector((store) => store.user);
  const override = {
    borderColor: "#4A249D",
    color: "#4A249D",
    alignSelf: "center",
  };
  const openLayout = () => {
    if (collapsed) setCollapsed(false);
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onClick={openLayout}
      >
        <div className="demo-logo-vertical" />
        <NewFilter openLayout={openLayout} collapsed2={collapsed} />
      </Sider>
      <Layout
        style={{
          background: colorBgContainer,
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {" "}
          <Flex
            width={"100%"}
            justifyContent={"start"}
            marginTop={"1rem"}
            gap={".5rem"}
          >
            <Button
              style={{ alignSelf: "center" }}
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />

            <Button
              icon={<FaTableCells />}
              style={view === "grid" ? override : { alignSelf: "center" }}
              onClick={() => {
                dispatch(changeViewTo("grid"));
                doNothing();
              }}
            />
            <Button
              onClick={() => {
                dispatch(changeViewTo("list"));
                doNothing();
              }}
              icon={<FaTableList />}
              style={view === "list" ? override : { alignSelf: "center" }}
            />
            <Text
              alignSelf={"center"}
              style={{
                padding: 0,
                height: 0,
                width: "60%",
                border: ".5px solid #4A249D",
              }}
            />
            {((!accountant && user.role === "Admin") ||
              user.role === "Staff") &&
              component}
          </Flex>
        </Header>
        <Content
          style={{
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default NewAllOrdersLayout;
