import React, { useEffect } from "react";
import { Flex, Radio, RadioGroup } from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import QuestionsWithoutInput from "./QuestionsWithoutInput";
import {
  addAnswerToTheOrder,
  addQuestionToTheOrder,
  deleteAnswerFromTheOrder,
} from "../../features/products/ProductSlice";
function RadioAnswers({ item }) {
  const { orderAnswers } = useSelector((state) => state.products);
  let defaultChecked = "";
  let arr = item.children.filter((e) => {
    return orderAnswers.includes(e.id);
  });
  if (arr.length > 0) defaultChecked = arr[0].id;
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.lang);
  let value = defaultChecked;
  useEffect(() => {
    dispatch(addQuestionToTheOrder(item.id));
  }, []);
  const handleChange = (newValue) => {
    dispatch(deleteAnswerFromTheOrder(value));
    dispatch(addAnswerToTheOrder(newValue));
    let arr = item.children.filter((e) => {
      return e.id === newValue;
    });

    value = newValue;
  };

  return (
    <RadioGroup onChange={handleChange} value={value}>
      {item?.children?.map((answer, index) => {
        return (
          <Flex>
            <Flex marginLeft={"1rem"} flexDir={"column"} minW={"480px"}>
              <Radio value={answer?.id}>
                {language === "English" ? answer?.value?.en : answer.value.de}
              </Radio>

              {value === answer?.id &&
                answer.children.map((question, index) => {
                  return <QuestionsWithoutInput key={index} item={question} />;
                })}
            </Flex>
          </Flex>
        );
      })}
    </RadioGroup>
  );
}

export default RadioAnswers;
