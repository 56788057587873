import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { getTheError } from "../../utils/ErorrHandling";

import { ORDER } from "../../utils/urls";

const initialFilterState = {
  search: "",
  orderType: "all",
  state: "all",
  startDate: "all",
  endDate: "all",
  productId: "all",
  sortedAsc: false,
  priority: "all",
  hasOrderInvoice: "all",
  isPaid: "all",
};
const initialState = {
  isLoading: false,
  pdfIsloading: false,
  updateIsLoading: false,
  pages: 1,
  pageNumber: 10,
  itemPerPage: 100,
  orders: [],
  ...initialFilterState,
  exportedOrders: [],
  view: "list",
};

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (data, thunkAPI) => {
    try {
      let {
        search,
        isPaid,
        startDate,
        endDate,
        state,
        hasOrderInvoice,
        priority,
        productId,
        sortedAsc,
      } = thunkAPI.getState().orders;
      if (
        thunkAPI.getState().user.user.role === "Accountant" ||
        thunkAPI.getState().user.accountant
      ) {
        state = 4;
      }
      if (startDate === undefined) startDate = "all";
      if (endDate === undefined) endDate = "all";
      if (hasOrderInvoice === undefined) hasOrderInvoice = "all";
      if (isPaid === undefined) isPaid = "all";
      let url =
        thunkAPI.getState().user.user.role === "Clinic"
          ? ORDER + `getmyorders?itemPerPage=${data.itemPerPage}`
          : ORDER + `getorders?itemPerPage=${data.itemPerPage}`;

      const resp = await axios.get(
        url +
          `&page=${data.page}` +
          (state !== "all" ? `&state=${state}` : "") +
          (productId !== "all" ? `&productId=${productId}` : "") +
          (search !== "" ? `&searchTerm=${search}` : "") +
          (startDate !== "all" ? `&startDate=${startDate}` : "") +
          (endDate !== "all" ? `&endDate=${endDate}` : "") +
          `&sortedAsc=${sortedAsc}` +
          (priority !== "all" ? `&isUrgent=${priority}` : "") +
          (hasOrderInvoice === "all"
            ? ""
            : `&hasOrderInvoice=${hasOrderInvoice}`) +
          (isPaid === "all" ? "" : `&isPaid=${isPaid}`),
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const changeOrdersState = createAsyncThunk(
  "orders/changeOrdersState",
  async (data, thunkAPI) => {
    try {
      let url = ORDER + `change-orders-state?state=${4}`;

      const resp = await axios.put(url, data.orders, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const exportPDF = createAsyncThunk(
  "orders/exportPDF",
  async (data, thunkAPI) => {
    try {
      let {
        search,
        // orderType,
        startDate,
        endDate,
        state,
        // pageNumber,
        priority,
        productId,
        sortedAsc,
      } = thunkAPI.getState().orders;
      if (startDate === undefined) startDate = "all";
      if (endDate === undefined) endDate = "all";
      let url = ORDER + `Export?`;

      const resp = await axios.get(
        url +
          (state !== "all" ? `&state=${state}` : "") +
          (productId !== "all" ? `&productId=${productId}` : "") +
          (search !== "" ? `&searchTerm=${search}` : "") +
          (startDate !== "all" ? `&startDate=${startDate}` : "") +
          (endDate !== "all" ? `&endDate=${endDate}` : "") +
          `&sortedAsc=${sortedAsc}` +
          (priority !== "all" ? `&isUrgent=${priority}` : ""),
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
const allOrdersSlice = createSlice({
  name: "allOrders",
  initialState,
  reducers: {
    clearFilters: (state) => {
      return { ...state, ...initialFilterState };
    },
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    applyFilters: (state, { payload }) => {
      state.startDate = payload?.startDate;
      state.endDate = payload?.endDate;
      state.search = payload?.search;
      state.state = payload?.state;
      state.sortedAsc = payload?.sortedAsc;
      state.productId = payload?.productId;
      state.priority = payload?.priority;
      state.hasOrderInvoice = payload.hasOrderInvoice;
      state.isPaid = payload.isPaid;
    },
    changeViewTo(state, { payload }) {
      state.view = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload && payload?.data) {
        state.orders = payload?.data;
        state.pages = payload?.data.pages;
      } else state.orders = [];
    });
    builder.addCase(getOrders.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(exportPDF.pending, (state) => {
      state.pdfIsloading = true;
    });
    builder.addCase(exportPDF.fulfilled, (state, { payload }) => {
      state.pdfIsloading = false;
      if (payload && payload?.data) {
        state.exportedOrders = payload?.data;
      } else state.orders = [];
    });
    builder.addCase(exportPDF.rejected, (state, { payload }) => {
      state.pdfIsloading = false;
      message.error(payload);
    });
    builder.addCase(changeOrdersState.pending, (state) => {
      state.updateIsLoading = true;
    });
    builder.addCase(changeOrdersState.fulfilled, (state, { payload }) => {
      state.updateIsLoading = false;

      message.success(payload?.data);
    });
    builder.addCase(changeOrdersState.rejected, (state, { payload }) => {
      state.updateIsLoading = false;
      message.error(payload);
    });
  },
});
export default allOrdersSlice.reducer;
export const { changeViewTo, clearFilters, handleChange, applyFilters } =
  allOrdersSlice.actions;
