import React, { useEffect, useState } from "react";
import Questions from "./Questions";
import { Box, Button, Container, Flex, Input } from "@chakra-ui/react";
import { FloatButton, Space } from "antd";
import { AddIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestion,
  buildTheUi,
  clearTheData,
  createProduct,
} from "../../features/products/ProductSlice";
import { generateUUID } from "../../utils/Tools";

import QuestionsWithoutInput from "./QuestionsWithoutInput";
import Loading from "../Loading";
function NewProduct() {
  const dispatch = useDispatch();
  const addQuestionHandler = () => {
    const question = {
      id: generateUUID(),
      parentID: "root",
      children: [],
      type: 1,
      value: { en: "", de: "" },
    };
    dispatch(addQuestion(question));
    dispatch(buildTheUi());
  };
  const { buttonsText } = useSelector((state) => state.lang);
  const { ui, questions, answers, isLoading } = useSelector(
    (state) => state.products
  );
  const [productEng, setProductEng] = useState("");
  const [productGer, setProductGer] = useState("");
  const addHandler = () => {
    let ch = true;
    const product = {
      productId: generateUUID(),
      data: {
        name: { en: productEng.trim(), de: productGer.trim() },
        questions: questions?.map((e) => {
          if (e === null || e === undefined) {
            ch = false;
            return;
          }
          if (e?.value["en"] === "" || e?.value["de"] === "") {
            ch = false;
            return;
          }
          return { ...e, children: [] };
        }),
        answers: answers?.map((e) => {
          if (e === null || e === undefined) {
            ch = false;
            return;
          }
          if (e?.value["en"] === "" || e?.value["de"] === "") {
            ch = false;
            return;
          }

          return { ...e, children: [] };
        }),
      },
    };
    if (ch === false) return;
    if (productEng !== "" && productGer !== "") {
      dispatch(createProduct(product.data));
      dispatch(clearTheData());
    }
  };
  const handlerEng = (e) => {
    setProductEng(e.target.value);
  };
  const handlerGer = (e) => {
    setProductGer(e.target.value);
  };
  useEffect(() => {
    dispatch(clearTheData());
  }, []);
  if (isLoading) return <Loading />;
  return (
    <Box
      p={"1rem"}
      boxShadow={"md"}
      w={"90%"}
      minH={"75%"}
      position={"relative"}
      paddingBottom={"5rem"}
    >
      {/* <Flex w={"100%"}> */}
      <Flex w={"100%"} flexDir={"column"} gap={".5rem"}>
        <Button
          width={"fit-content"}
          alignSelf={"end"}
          variant={"outline"}
          onClick={addHandler}
        >
          {buttonsText.create}
        </Button>

        <Input placeholder="productName" onChange={handlerEng}></Input>

        <Input placeholder="produktName" onChange={handlerGer}></Input>
        {ui.map((item, index) => {
          return <Questions item={item} key={index} num={`Q${index + 1}`} />;
        })}
      </Flex>

      {ui.map((item, index) => {
        return (
          <QuestionsWithoutInput
            item={item}
            key={index}
            num={`Q${index + 1}`}
          />
        );
      })}
      <FloatButton
        style={{
          position: "absolute",
        }}
        onClick={addQuestionHandler}
        icon={<AddIcon />}
      ></FloatButton>
    </Box>
  );
}

export default NewProduct;
