import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { getTheError } from "../../utils/ErorrHandling";
import { BASEURL } from "../../utils/urls";
import {
  addProductsToLocalSorage,
  getProductsFromLocalStorage,
} from "../../utils/LocalStorage";
import { message } from "antd";

const initialState = {
  isLoading: false,
  products: getProductsFromLocalStorage(),
  tempData: [],
  questions: [],
  answers: [],
  ids: [],
  ui: [],
  uis: [],
  orderAnswers: [],
  orderQuestions: [],
  updatedtedSuccessfuly: false,
  curProduct: {},
};

export const getProduct = createAsyncThunk(
  "products/getProduct",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(`${BASEURL}/api/product/getproduct/${id}`, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getProducts = createAsyncThunk(
  "products/getProducts",

  async (_, thunkAPI) => {
    try {
      const resp = await axios.get(`${BASEURL}/api/product/getproductlist`, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (product, thunkAPI) => {
    try {
      const resp = await axios.post(
        `${BASEURL}/api/product/addproduct`,
        product,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (product, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${BASEURL}/api/product/updateProduct/${product.productId}`,
        product,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const deleteFromTheProduct = createAsyncThunk(
  "products/deleteFromProduct",
  async (product, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${BASEURL}/api/product/delete/${product.id}/${product.type}`,

        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      thunkAPI.dispatch(getProducts());
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const changeActivation = createAsyncThunk(
  "products/changeActivation",
  async (product, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${BASEURL}/api/product/chanageactive/${product.id}`,
        {},

        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return product;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updatedtedSuccessfuly(state, { payload }) {
      state.updatedtedSuccessfuly = payload;
    },
    setProductToUpdate(state, { payload }) {
      const products = state.products.filter((e) => {
        return e.id === payload;
      });
      if (products.length > 0) {
        state.questions = products[0]?.questions;
        state.answers = products[0]?.answers;
      }
    },
    buildTheUi(state) {
      state.ui = [];
      const curQ = state.questions.map((e) => {
        e.children = [];
        return e;
      });
      const curA = state.answers.map((e) => {
        e.children = [];
        return e;
      });
      const dfs = (item) => {
        curQ.forEach((e) => {
          if (e.parentID === item.id) {
            dfs(e);

            item.children.push(e);
          }
        });
        curA.forEach((e) => {
          if (e.parentID === item.id) {
            dfs(e);
            item.children.push(e);
          }
        });
      };
      curQ.forEach((e) => {
        if (e.parentID === "root" || e.parentID === null) {
          let temp = { ...e, parentID: null };

          dfs(temp);
          state.ui.push(temp);
        }
      });
    },
    addQuestion(state, { payload }) {
      let ch = false;
      state.questions = state.questions.map((e) => {
        if (e.id === payload.id) {
          e.value.en = payload.value.en;
          e.value.de = payload.value.de;
          e.type = payload.type;
          e.isEdited = payload.isEdited;
          ch = true;
        }
        return e;
      });
      if (ch === false) {
        state.questions.push(payload);
      }
    },
    addAnswer(state, { payload }) {
      let ch = false;
      state.answers = state.answers.map((e) => {
        if (e.id === payload.id) {
          e.value.en = payload.value.en;
          e.value.de = payload.value.de;
          e.isEdited = payload.isEdited;

          ch = true;
        }
        return e;
      });
      if (ch === false) {
        state.answers.push(payload);
      }
    },
    deleteTheTRee(state, { payload }) {
      // i think bfs will be good in this method then call the builder
      const id = payload.id;
      const queue = [payload.item];

      while (queue.length > 0) {
        const cur = queue[0];
        queue.shift();
        state.questions = state.questions.filter((e) => {
          if (e.parentID === cur) queue.push(e.id);
          return e.parentID !== cur && e.id !== cur;
        });
        state.answers = state.answers.filter((e) => {
          if (e.parentID === cur) queue.push(e.id);
          return e.parentID !== cur && e.id !== cur;
        });
      }
      state.products.map((e) => {
        if (e.id === id) {
          const product = { ...e };
          product.questions = state.questions;
          product.answers = state?.answers;
          return product;
        } else return e;
      });
      addProductsToLocalSorage(state.products);
    },
    saveProduct(state, { payload }) {
      state.tempData = state.tempData.filter((e) => {
        return e.productId !== payload.productId;
      });
      let product = { ...payload };
      product.data.questions = product.data.questions.map((e) => {
        return { ...e, children: [] };
      });
      product.data.answers = product.data.answers.map((e) => {
        return { ...e, children: [] };
      });
      state.tempData.push(payload);
    },
    clearTheData(state) {
      state.answers = [];
      state.questions = [];
      state.ui = [];
      state.uis = [];
      state.ids = [];
      state.orderAnswers = [];
      state.orderQuestions = [];
    },
    getTheProduct(state) {
      state.products = getProductsFromLocalStorage();
    },
    setTheProduct(state, { payload }) {
      const temp = [...state.tempData];

      let product;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].productId === payload) {
          product = temp[i];
        }
      }

      state.questions = product.data.questions;
      state.answers = product.data.answers;
    },
    buildTheUis(state, { payload }) {
      state.uis = state.uis.filter((e) => {
        return payload.data.includes(e.id);
      });
      let products = state.products.filter((product) => {
        return payload.data?.includes(product.id);
      });

      let allIds = [];
      for (let index = 0; index < products.length; index++) {
        allIds.push(...products[index].questions.map((e) => e.id));
        allIds.push(...products[index].answers.map((e) => e.id));

        if (state.ids.includes(products[index].id)) {
          continue;
        }

        state.questions = products[index].questions;
        state.answers = products[index].answers;
        ///////////////////////////////////

        state.ui = [];
        const curQ = state.questions.map((e) => {
          e.children = [];
          return e;
        });
        const curA = state.answers.map((e) => {
          e.children = [];
          return e;
        });
        const dfs = (item) => {
          curQ.forEach((e) => {
            if (e.parentID === item.id) {
              dfs(e);

              item.children.push(e);
            }
          });
          curA.forEach((e) => {
            if (e.parentID === item.id) {
              dfs(e);
              item.children.push(e);
            }
          });
        };
        curQ.forEach((e) => {
          if (e.parentID === "root" || e.parentID === null) {
            let temp = { ...e, parentID: null };

            dfs(temp);
            state.ui.push(temp);
          }
        });

        state.uis.push({
          id: products[index].id,
          name: products[index].name,
          data: state.ui,
        });
      }
      state.orderAnswers = state.orderAnswers.filter((e) => {
        return allIds.includes(e);
      });
      state.orderQuestions = state.orderQuestions.filter((e) => {
        return allIds.includes(e);
      });
      state.ids = [...payload.data];
    },
    addAnswerToTheOrder(state, { payload }) {
      if (state.orderAnswers.includes(payload)) {
        // remove the hole tree
      } else {
        state.orderAnswers.push(payload);
      }
    },
    addQuestionToTheOrder(state, { payload }) {
      if (state.orderQuestions.includes(payload)) {
        // remove the hole tree
      } else {
        state.orderQuestions.push(payload);
      }
    },
    deleteAnswerFromTheOrder(state, { payload }) {
      if (payload === "") return;
      state.orderAnswers = state.orderAnswers.filter((e) => {
        return e !== payload;
      });

      let arr = state.products.filter((e) => {
        return state.ids.includes(e.id);
      });

      let item = arr[0];
      for (let i = 0; i < arr.length; i++) {
        let ch = false;
        for (let j = 0; j < arr[i].answers.length; j++) {
          if (arr[i].answers[j].id === payload) {
            item = arr[i];
            ch = true;
            break;
          }
        }
        if (ch === true) break;
      }

      const allIds = [];

      const queue = [payload];
      while (queue.length > 0) {
        const cur = queue[0];
        allIds.push(cur);
        queue.shift();

        for (let i = 0; i < item?.questions?.length; i++) {
          const temp = item?.questions[i];
          if (temp?.parentID === cur) {
            queue.push(temp.id);
          }
        }
        for (let i = 0; i < item?.answers?.length; i++) {
          const temp = item?.answers[i];
          if (temp?.parentID === cur) {
            queue.push(temp.id);
          }
        }
      }

      state.orderAnswers = state.orderAnswers.filter((e) => {
        return allIds.includes(e) === false;
      });
      state.orderQuestions = state.orderQuestions.filter((e) => {
        return allIds.includes(e) === false;
      });
    },

    deleteQuestionFromTheOrder(state, { payload }) {},

    getTheQuestions(state, { payload }) {
      let ids = [];
      let questions = [];

      for (let j = 0; j < state.products.length; j++) {
        const p = state.products[j];

        for (let i = 0; i < p.answers.length; i++) {
          let ans = p.answers[i];

          if (
            payload.includes(ans.id) &&
            questions.includes(ans.parentID) === false
          ) {
            questions.push(ans.parentID);
            if (ids.includes(p.id) == false) {
              ids.push(p.id);
            }
          }
        }
      }
      state.orderAnswers = payload;
      state.ids = ids;
      state.orderQuestions = questions;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = payload?.data;

      addProductsToLocalSorage(payload?.data);
    });
    builder.addCase(getProducts.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(createProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      state.products.unshift(payload?.data);
      addProductsToLocalSorage(state.products);
    });
    builder.addCase(createProduct.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(updateProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      state.products = state.products.map((e) => {
        if (e.id === payload?.data?.id) {
          return payload.data;
        } else return e;
      });

      state.questions = payload?.data?.questions;
      state.answers = payload?.data?.answers;
      addProductsToLocalSorage(state.products);
      message.success("success");

      state.updatedtedSuccessfuly = true;
    });
    builder.addCase(updateProduct.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });

    builder.addCase(deleteFromTheProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFromTheProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      message.success(payload.data);
    });
    builder.addCase(deleteFromTheProduct.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(changeActivation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeActivation.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      state.products = state.products.map((item) => {
        if (item.id === payload?.id) {
          item.isActive = !item.isActive;
        }
        return item;
      });
    });
    builder.addCase(getProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.questions = payload.data.questions;
      state.answers = payload.data.answers;
    });

    builder.addCase(getProduct.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
  },
});
export const {
  getTheProduct,
  deleteTheTRee,
  addAnswer,
  addQuestion,
  buildTheUi,
  saveProduct,
  clearTheData,
  setTheProduct,
  buildTheUis,
  addAnswerToTheOrder,
  addQuestionToTheOrder,
  deleteAnswerFromTheOrder,
  deleteQuestionFromTheOrder,
  getTheQuestions,
  setProductToUpdate,
  updatedtedSuccessfuly,
} = productSlice.actions;
export default productSlice.reducer;
