import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { getTheError } from "../../utils/ErorrHandling";
import { BASEURL } from "../../utils/urls";

const initialFilterState = {
  search: "",
  orderType: "all",
  state: "all",
  startDate: "all",
  endDate: "all",
  productId: "all",
  sortedAsc: false,
};
const initialState = {
  isLoading: false,
  pages: 1,
  pageNumber: 1,
  itemPerPage: 10,
  orders: [],
  ...initialFilterState,
};

export const getOrders = createAsyncThunk(
  "bin/getOrders",
  async (data, thunkAPI) => {
    try {
      let { search, startDate, endDate, state, pageNumber, sortedAsc } =
        thunkAPI.getState().bin;

      if (startDate === undefined) startDate = "all";
      if (endDate === undefined) endDate = "all";
      let url = `${BASEURL}/api/order/recyclebin/getorders?itemPerPage=${data.itemPerPage}`;

      const resp = await axios.get(
        url +
          `&page=${data.page}` +
          (state !== "all" ? `&state=${state}` : "") +
          (search !== "" ? `&searchTerm=${search}` : "") +
          (startDate !== "all" ? `&startDate=${startDate}` : "") +
          (endDate !== "all" ? `&endDate=${endDate}` : "") +
          `&sortedAsc=${sortedAsc}`,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const retriveOrder = createAsyncThunk(
  "bin/retriveOrder",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${BASEURL}/api/order/recyclebin/retrieveorder/` + data,
        {},
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      resp.data.id = data;
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const deleteOrder = createAsyncThunk(
  "bin/deleteOrder",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${BASEURL}/api/order/recyclebin/deleteorder/` + data,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      resp.data.id = data;
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

const binSlice = createSlice({
  name: "bin",
  initialState,
  reducers: {
    clearFilters: (state) => {
      return { ...state, ...initialFilterState };
    },
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    applyFilters: (state, { payload }) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
      state.search = payload.search;
      state.state = payload.state;
      state.sortedAsc = payload.sortedAsc;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload && payload.data) {
        state.orders = payload.data;
        state.pages = payload.data.pages;
      } else state.orders = [];
    });
    builder.addCase(getOrders.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(retriveOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(retriveOrder.fulfilled, (state, { payload }) => {
      message.success(payload.data);
      state.orders.orders = state.orders.orders.filter(
        (item) => item.id !== payload.id
      );
      state.isLoading = false;
    });
    builder.addCase(retriveOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(deleteOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteOrder.fulfilled, (state, { payload }) => {
      state.orders.orders = state.orders.orders.filter(
        (item) => item.id !== payload.id
      );

      message.success(payload.data);
      state.isLoading = false;
    });
    builder.addCase(deleteOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
  },
});
export default binSlice.reducer;
export const { clearFilters, handleChange, applyFilters } = binSlice.actions;
