import { Button, Flex, InputGroup } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";

import TextEditor from "../../pages/TextEditor";
let isTyping = false;
function ChatControle({ sendMessage, type }) {
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (message.trim() !== "" && isTyping === false) {
      type(true);
      isTyping = true;
    }
    if (message.trim() === "" && isTyping === true) {
      type(false);
      isTyping = false;
    }
  }, [message]);
  const changeHandler = (e) => {
    setMessage(e);
  };

  return (
    <Flex alignItems={"center"} justifyContent={"center"}>
      <InputGroup w={["100%", "100%", "80%", "60%"]} position="relative">
        <TextEditor changeHandler={changeHandler} message={message} />
        <Button
          colorScheme="purple"
          position="absolute"
          variant={"ghost"}
          bottom="2rem" // Adjust as necessary for positioning
          right="0rem" // Adjust as necessary for positioning
          onClick={() => {
            if (message.trim() !== "") {
              sendMessage(message);
            }
            setMessage("");
          }}
        >
          <IoIosSend />
        </Button>
      </InputGroup>
    </Flex>
  );
}

export default ChatControle;
