import React, { useEffect, useState } from "react";
import "../assets/style.css";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Divider,
  Text,
  Flex,
  Avatar,
} from "@chakra-ui/react";
import { BellIcon } from "@chakra-ui/icons";
import { FaCheckDouble } from "react-icons/fa6";
import { Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { markAllAsRead } from "../features/notification/notificationSlice";
function Notification() {
  function formatDateToGerman(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const germanDate = date.toLocaleDateString("de-DE", options);
    return germanDate;
  }
  const dispatch = useDispatch();

  const {
    notifications: txt,
    buttonsText,
    empty,
  } = useSelector((store) => store.lang);

  let { notifications, total } = useSelector((store) => store.notification);

  if (notifications === undefined) {
    notifications = [];
  }
  const [items, setItems] = useState(notifications);
  const navigate = useNavigate();
  const getText = (item) => {
    if (item.state === 0) {
      return `${item.username} ${txt.createOrder}`;
    } else if (item.state === 1) {
      return `${item.username} ${txt.updateChanges}`;
    } else if (item.username === null || item.username === undefined) {
      return `${txt.updateMyState2}`;
    } else return `${item.username} ${txt.updateState2}`;
  };
  useEffect(() => {
    setItems(notifications);
  }, [notifications]);
  return (
    <Menu>
      <MenuButton pos={"relative"} as={Button} onClick={() => {}}>
        {total > 0 && (
          <Text
            textAlign={"center"}
            bg={"red"}
            pos={"absolute"}
            color={"white"}
            right={"0"}
            top={"0"}
            fontSize={"md"}
            rounded={"50%"}
            width={"1.3rem"}
            height={"1.3rem"}
          >
            {total}
          </Text>
        )}
        <BellIcon />
      </MenuButton>
      <MenuList
        minW={"350px"}
        p={"1rem"}
        minH={"50vh"}
        maxH={"70vh"}
        overflow={"scroll"}
      >
        {items?.length > 0 ? (
          <Flex width={"100%"} justifyContent={"end"}>
            <Button
              onClick={() => {
                dispatch(markAllAsRead());
              }}
              leftIcon={<FaCheckDouble />}
              colorScheme="blue"
              variant={"link"}
            >
              {buttonsText.markAsRead}
            </Button>
          </Flex>
        ) : (
          <Empty description={empty.empty} />
        )}
        {items?.map((item, idx) => {
          return (
            <Box
              position={"relative"}
              key={idx}
              onClick={() => {
                navigate("/order-info", {
                  state: {
                    id: item.orderId,
                    notificationId: item.id,
                  },
                });
              }}
              p=".2rem"
              rounded={"md"}
              boxShadow={"sm"}
            >
              <MenuItem
                minH="48px"
                p={".5rem"}
                display={"flex"}
                gap=".6rem"
                alignItems={"center"}
              >
                <Avatar
                  bgColor={"#D895DA"}
                  name={item.username}
                  width={"30px"}
                  height={"30px"}
                ></Avatar>

                <Flex direction={"column"} width={"100%"}>
                  <Text fontWeight={"semi-bold"} fontSize={".8rem"}>
                    {getText(item)}
                  </Text>
                  <Text
                    fontSize={".7rem"}
                    opacity={".8"}
                    justifySelf={"end"}
                    alignSelf={"end"}
                  >
                    {formatDateToGerman(item.createdAt) === "Invalid Date"
                      ? ""
                      : formatDateToGerman(item.createdAt)}
                  </Text>
                </Flex>
              </MenuItem>
              <Divider />
            </Box>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default Notification;
