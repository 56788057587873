export const validate = (values) => {
  // Helper function to perform validation and return an error object if validation fails
  const validateField = (value, regex, errorMessage) => {
    return value.match(regex) === null
      ? { respond: "error", msg: errorMessage }
      : null;
  };

  if (values.Role === "clinic") {
    const {
      userName,
      name,
      email,
      password,
      phone,
      doctorName,
      country,
      location,
      fullLocation,
    } = values;
    // Validation using regular expressions
    const isAlphabetic = /^[a-zA-Z-'s]+$/;
    const isAlphaNumeric = /^[a-zA-Z0-9\s]+$/;
    const isAlphabeticWithSpaces = /^[a-zA-Z\s-'s]+$/;

    // Validation checks
    const userNameError = validateField(
      userName,
      isAlphabetic,
      "Invalid userName"
    );
    if (userNameError) return userNameError;

    const nameError = validateField(
      name,
      isAlphabeticWithSpaces,
      "Invalid name"
    );
    if (nameError) return nameError;

    const emailError =
      email === "" ? { respond: "error", msg: "Invalid email" } : null;
    if (emailError) return emailError;

    if (phone.trim() === "") {
      return { respond: "error", msg: "invalid Phone number" };
    }

    const passwordError = validateField(
      password,
      /^.{8,40}$/,
      "Invalid password"
    );
    if (passwordError) return passwordError;

    const doctorNameError = validateField(
      doctorName,
      isAlphabetic,
      "Invalid doctor name"
    );
    if (doctorNameError) return doctorNameError;
    if (country.trim() === "") {
      return { respond: "error", msg: "country Can't be empty" };
    }

    const locationError = validateField(
      location,
      isAlphaNumeric,
      "Invalid location"
    );
    if (locationError) return locationError;
    // console.log(fullLocation.trim());
    if (fullLocation.trim() === "") {
      return { respond: "error", msg: "Location Can't be empty" };
    }
  } else {
    const { userName, name, password } = values;

    // Validation checks
    const userNameError = validateField(
      userName,
      /^[a-zA-Z-'s]+$/,
      "Invalid userName"
    );
    if (userNameError) return userNameError;

    const nameError = validateField(
      name,
      /^[a-zA-Z\s-'s]+$/, // Allow spaces in the name
      "Invalid name"
    );
    if (nameError) return nameError;

    const passwordError = validateField(
      password,
      /^.{8,40}$/,
      "Invalid password"
    );
    if (passwordError) return passwordError;
  }

  return { respond: "success", msg: "submitted" };
};
