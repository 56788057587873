import {
  Heading,
  Avatar,
  Button,
  Divider,
  Text,
  SimpleGrid,
  GridItem,
  useColorMode,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import { BASEURL } from "../utils/urls";

function UserCard({ color, Role, info, handler }) {
  const data = { info: Object.assign({}, info) };
  // Make a deep copy of the info object and its nested properties
  data.info = JSON.parse(JSON.stringify(info));

  const { colorMode } = useColorMode();
  const { user } = useSelector((store) => store.user);

  const { userText, buttonsText, role } = useSelector((store) => store.lang);
  const avatarCols = Role === "clinic" ? 2 : 2,
    userNameCols = Role === "clinic" ? 3 : 3,
    roleCols = Role === "Clinic" ? 2 : 2,
    countryCols = Role === "clinic" ? 2 : 2,
    activateCols = Role === "clinic" ? 2 : 2,
    btnCols = Role === "clinic" ? 2 : 2;
  return (
    <>
      <SimpleGrid
        h={"fit-content"}
        paddingX={"0.5rem"}
        py={"0.5rem"}
        transition={"all .2s ease"}
        _hover={{
          backgroundColor: "#F0F3FF",
          cursor: "pointer",
        }}
        columns={11}
        justifyContent={"center"}
        alignItems={"center"}
        rounded={"md"}
      >
        <GridItem colSpan={avatarCols}>
          <Avatar
            ml={1}
            color={"white"}
            src={`${BASEURL}/api/veHFSaSyDihimAicqjtcpYuPwpxvdX/${info.profileImage}`}
            name={info.name}
            size={"md"}
            bg={color}
          />
        </GridItem>
        {Role !== "clinic" && (
          <GridItem colSpan={roleCols}>
            <Heading fontSize={"sm"} fontWeight={500} fontFamily={"body"}>
              {info.role === "Admin" ? role.admin : role.staff}
            </Heading>
          </GridItem>
        )}
        <GridItem colSpan={userNameCols}>
          <Heading
            fontSize={"sm"}
            fontWeight={500}
            fontFamily={"body"}
            color={
              info.role === "Admin"
                ? colorMode === "dark"
                  ? "pink.600"
                  : "yellow.600"
                : "inherit"
            }
          >
            {info.username}
          </Heading>
        </GridItem>

        {Role === "clinic" && (
          <GridItem colSpan={countryCols}>
            <Text fontSize={"md"} fontWeight={"semibold"}>
              {info.country}
            </Text>
          </GridItem>
        )}
        <GridItem colSpan={activateCols}>
          {user.role === "Admin" ? (
            <Button
              display={info.userId === user.userId ? "none" : "block"}
              colorScheme={info.isActive ? "green" : "red"}
              onClick={handler}
              rounded={"md"}
            >
              {info.isActive ? buttonsText.activate : buttonsText.inactivate}
            </Button>
          ) : (
            <Text
              width={"fit-content"}
              padding={".8rem"}
              bg={info.isActive ? "green" : "red"}
              color={"white"}
              rounded={"md"}
              _hover={{
                cursor: "not-allowed",
              }}
            >
              {info.isActive ? buttonsText.activate : buttonsText.inactivate}
            </Text>
          )}
        </GridItem>
        <GridItem colSpan={btnCols}>
          <Link to="/update-user" state={{ user: info }}>
            <Button onClick={() => {}} variant={"outline"} rounded={"md"}>
              {userText.moreInfo}
            </Button>
          </Link>
        </GridItem>
      </SimpleGrid>
      <Divider />
    </>
  );
}

export default UserCard;
