import React, { useState } from "react";
import { CustomerServiceOutlined } from "@ant-design/icons";
import { MdCreate } from "react-icons/md";
import { BiUserPlus } from "react-icons/bi";
import { GiBugleCall } from "react-icons/gi";
import { FloatButton, Tooltip } from "antd";

import {
  convertAdminToAcc,
  returnToAdminFromAcc,
} from "../features/user/UserSlice";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiBank } from "react-icons/ci";
import { clearFilters } from "../features/allOrders/allOrdersSlice";
const FloatingsControles = ({ onOpen }) => {
  const { accountant } = useSelector((state) => state.user);
  const { sideBar } = useSelector((store) => store.lang);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <FloatButton.Group
        open={open}
        trigger="click"
        style={{
          insetInlineEnd: 24,
        }}
        onClick={() => {
          setOpen(!open);
        }}
        icon={<CustomerServiceOutlined />}
      >
        <Tooltip title={"Accountant mode"}>
          <FloatButton
            icon={<CiBank />}
            onClick={() => {
              if (accountant) {
                dispatch(returnToAdminFromAcc());
                dispatch(clearFilters());
                navigate("/");
              } else {
                dispatch(convertAdminToAcc());
                dispatch(clearFilters());
                navigate("/");
              }
            }}
          />
        </Tooltip>
        {accountant || (
          <Tooltip title={sideBar.addUser}>
            <FloatButton onClick={onOpen} icon={<BiUserPlus />} />
          </Tooltip>
        )}
        {accountant || (
          <Tooltip title={sideBar.addProduct}>
            <Link
              to="add-option"
              style={{ display: "block", marginBottom: "1rem" }}
            >
              <FloatButton icon={<MdCreate />} />
            </Link>
          </Tooltip>
        )}
        {accountant || (
          <Tooltip title={sideBar.addAd}>
            <Link to="addAd">
              <FloatButton icon={<GiBugleCall />}> </FloatButton>
            </Link>
          </Tooltip>
        )}
      </FloatButton.Group>
    </>
  );
};
export default FloatingsControles;
