import React, { useState } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  GridItem,
  Text,
  useColorMode,
  IconButton,
} from "@chakra-ui/react";
import { Select, Tree } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { buildAnswersTree } from "../../utils/Tools";
import { Pie } from "react-chartjs-2";
import { buildTheUis } from "../../features/products/ProductSlice";
import { DownOutlined } from "@ant-design/icons";
import QuestionsWithoutInput from "../product/QuestionsWithoutInput";

import { SearchIcon } from "@chakra-ui/icons";
import { getProductStatisticsFiltred } from "../../features/statistics/statisticsSlice";
let options = [];
function ProductsFilter({ id, close }) {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const { language } = useSelector((store) => store.lang);
  const [selectedRange, setSelectedRange] = useState([]);

  const { uis, products, orderAnswers, ids } = useSelector(
    (store) => store.products
  );
  const { colorMode } = useColorMode();
  const lang = language === "English" ? 0 : 1;
  const { products: productsStats } = useSelector((store) => store.statistics);
  const handleChange = (dates, dateStrings) => {
    // Format the date strings to "MM/DD/YYYY" and update state
    setSelectedRange(
      dateStrings?.map((date) => moment(date).format("MM/DD/YYYY"))
    );
  };
  const [values, setValues] = useState(ids);
  const submitHandler = () => {
    if (values.length < 1) return;

    const ourProducts = products.filter((e) => {
      return values.includes(e.id);
    });

    const ourAnswers = [...orderAnswers];

    const data = {};
    const productData = {};
    ourProducts.forEach((product) => {
      productData[product.id] = product.answers
        .filter((ans) => {
          return ourAnswers.includes(ans.id);
        })
        .map((item) => {
          return item.id;
        });
    });
    const [start, end] = selectedRange;

    data.startDate = start === "" ? null : start;
    data.endDate = end === "" ? null : end;
    data.productAnswerMap = productData;

    dispatch(getProductStatisticsFiltred(data));
  };
  const choiceProducts = (item) => {
    dispatch(buildTheUis({ data: item }));

    setValues(item);
  };

  options = products.map((item) => {
    return {
      label: language === "English" ? item.name.en : item.name.de,
      value: item.id,
    };
  });

  return (
    <Flex flexDir={"column"} w={"100%"}>
      <Flex gap={"1rem"} mb={"1rem"}>
        {" "}
        <Select
          mode="multiple"
          style={{
            width: "100%",
          }}
          defaultValue={values}
          onChange={choiceProducts}
          options={options}
        />
        <IconButton
          icon={<SearchIcon />}
          variant={"outline"}
          onClick={submitHandler}
        />
      </Flex>
      <RangePicker
        value={selectedRange?.map((date) => moment(date, "MM/DD/YYYY"))}
        format={"MM/DD/YYYY"}
        onChange={handleChange}
      />

      {uis.map((ui) => {
        return (
          <Flex
            mt={"1rem"}
            alignSelf={"start"}
            rounded={"md"}
            width={"100%"}
            flexDir={"column"}
            outline={"1px solid gray"}
            alignItems={"start"}
          >
            <Box p={"1rem"}>
              <Text>{ui.name.en}</Text>
            </Box>
            {ui?.data?.map((item, index) => {
              return (
                <QuestionsWithoutInput
                  item={item}
                  key={index}
                  num={`Q${index + 1}`}
                />
              );
            })}
            ;
          </Flex>
        );
      })}

      {productsStats?.map((product, index) => {
        let p = products.filter((e) => {
          return e.id === product.productId;
        });

        return (
          <SimpleGrid columns={10} border={"1px solid #eee"} padding={"1rem"}>
            <GridItem colSpan={10}>
              <Text>{product?.productName[0].Name}</Text>
            </GridItem>
            {
              <GridItem colSpan={4}>
                <Flex
                  w={"100%"}
                  h={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Tree
                    showLine
                    switcherIcon={<DownOutlined />}
                    defaultExpandAll={true}
                    treeData={buildAnswersTree(p, orderAnswers, language)}
                  />
                </Flex>
              </GridItem>
            }
            {
              <GridItem colSpan={4} minH={"360px"}>
                <Pie
                  options={{
                    plugins: {
                      legend: {
                        position: "bottom",
                        labels: {
                          color: colorMode === "dark" ? "white" : "black",
                          padding: 10,
                        },
                      },
                      title: {
                        display: true,
                        color: colorMode === "dark" ? "white" : "black",
                        text: product?.productName[lang].Name,
                        padding: {
                          top: 10,
                          bottom: 30,
                        },
                      },
                      tooltip: {
                        callbacks: {
                          label: function (tooltipItem) {
                            const data = tooltipItem.dataset.data;
                            const value = data[tooltipItem.dataIndex];
                            return value;
                          },
                        },
                      },
                      datalabels: {
                        formatter: (value, context) => {
                          const dataset = context.dataset.data;
                          const total = dataset.reduce(
                            (sum, val) => sum + val,
                            0
                          );
                          const percentage = ((value / total) * 100).toFixed(2);
                          return percentage > 0 ? `${percentage}%` : "";
                        },
                        color: colorMode === "dark" ? "white" : "black",
                        anchor: "end",
                        align: "start",
                      },
                    },
                  }}
                  data={{
                    labels: ["✓", "X"],

                    datasets: [
                      {
                        data: [
                          product.ordersWithAllAnswers,
                          product.count - product.ordersWithAllAnswers,
                        ],
                        backgroundColor: ["#96C9F4", "#eee"],
                      },
                    ],
                  }}
                />
              </GridItem>
            }
          </SimpleGrid>
        );
      })}
    </Flex>
  );
}

export default ProductsFilter;
