import { Heading, Text, Center, SimpleGrid } from "@chakra-ui/react";
import HeadingTitle from "../components/order/HeadingTitle";
import React, { useEffect, useState } from "react";
import Order from "../components/Order";
import { useColorMode } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, handleChange } from "../features/trash/trashSlice";
import Loading from "../components/Loading";
import FiltersOfTrash from "../components/FiltersOfTrash";
import { Pagination } from "antd";
import { Navigate } from "react-router-dom";
import { setActivePage } from "../features/chat/chatSlice";
import { Empty } from "antd";
import { logOut } from "../features/user/UserSlice";
function Trash() {
  const {
    pages,
    orders,
    isLoading,
    state,
    search,
    startDate,
    endDate,
    sortedAsc,
    itemPerPage,
  } = useSelector((store) => store.bin);
  const { heading, allOrders, empty } = useSelector((store) => store.lang);
  const { theme, mainColor } = useSelector((store) => store.theme);
  const dispatch = useDispatch();
  const { user: me } = useSelector((store) => store.user);
  useEffect(() => {
    if (me === undefined) dispatch(logOut());
  }, [me]);
  useEffect(() => {
    dispatch(setActivePage(100));
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(itemPerPage); // Default page size

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPageSize(size);
    dispatch(handleChange({ name: "itemPerPage", value: size }));
    setCurrentPage(1); // Reset to the first page when the page size changes
  };

  useEffect(() => {
    dispatch(
      getOrders({
        page: currentPage,
        itemPerPage: pageSize,
        state: "all",
      })
    );
  }, [search, state, startDate, endDate, currentPage, pageSize, sortedAsc]);
  const { user } = useSelector((state) => state.user);

  const { colorMode } = useColorMode();
  let items =
    orders === undefined || orders.length === 0
      ? []
      : orders.orders.map((item, index) => {
          let bcolor = `${
            index % 2 === 0
              ? `${colorMode === "dark" ? "gray.700" : "gray.50"}`
              : `${colorMode === "dark" ? "gray.800" : "gray.100"}`
          }`;
          return (
            <Order
              key={index}
              bcolor={bcolor}
              order={item}
              deactivated={true}
            ></Order>
          );
        });
  if (user.role !== "Admin") return <Navigate to={"/403"} />;
  if (isLoading) return <Loading />;
  const clinicCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    fnameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    snameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    recordCols = user.role === "Clinic" ? 2 : 2,
    statesCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    dateCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    productsCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2];
  const clinicDisplay = user.role === "Clinic" ? "none" : "block",
    fnameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    snameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    recordDisplay = user.role === "Clinic" ? "block" : "block",
    statesDisplay = user.role === "Clinic" ? "block" : "block",
    dateDisplay = user.role === "Clinic" ? "block" : "block",
    productsDisplay = user.role === "Clinic" ? "block" : "block";
  return (
    <>
      <Center>
        <Heading
          fontSize={"1.4rem"}
          as={"h4"}
          color={mainColor[theme]}
          p={"2rem"}
        >
          {heading.recycleBin}
        </Heading>
      </Center>
      <FiltersOfTrash />
      <SimpleGrid
        columns={user.role === "Clinic" ? 12 : 14}
        alignItems={"left"}
        w={"100%"}
        gap={"1rem"}
        my={"1rem"}
        color={colorMode === "dark" ? "gray.100" : "gray.500"}
        mt={"1rem"}
      >
        {user.role === "Clinic" || (
          <HeadingTitle
            value={allOrders.clinicName}
            cols={clinicCols}
            display={clinicDisplay}
          />
        )}

        <HeadingTitle
          value={allOrders.fName}
          cols={fnameCols}
          display={fnameDisplay}
        />
        <HeadingTitle
          value={allOrders.sName}
          cols={snameCols}
          display={snameDisplay}
        />
        <HeadingTitle
          value={allOrders.idNumber}
          cols={recordCols}
          display={recordDisplay}
        />
        <HeadingTitle
          value={allOrders.date}
          cols={dateCols}
          display={dateDisplay}
        />

        <HeadingTitle
          value={allOrders.state}
          cols={statesCols}
          display={statesDisplay}
        />
        <HeadingTitle
          value={allOrders.producs}
          cols={productsCols}
          display={productsDisplay}
        />
      </SimpleGrid>
      {items.length === 0 ? <Empty description={empty.empty} /> : items}

      <Center mt={"1rem"}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={pageSize * pages} // Replace with the actual total number of items
          onChange={handlePageChange}
          onShowSizeChange={handleSizeChange}
          showSizeChanger
          pageSizeOptions={["10", "20", "30", "40", "50"]} // Customize the available page sizes
        />
      </Center>
    </>
  );
}

export default Trash;
