import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { message } from "antd";
import axios from "axios";
import { INVOICE } from "../../utils/urls";

import { getTheError } from "../../utils/ErorrHandling";

const initialState = {
  invoice: {
    id: "",
    code: "",
    createdAt: "",
    updatedAt: "",
    paymentDate: null,
    isPaid: false,
    orderId: "",
    itmes: [],
  },
  invoiceIsLoading: false,
};
export const postInvoice = createAsyncThunk(
  "invoice/postInovice",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(INVOICE, data, {
        headers: { Authorization: thunkAPI.getState().user.user.token },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error);
    }
  }
);
export const updateInvoice = createAsyncThunk(
  "invoice/updateInovice",
  async (data, thunkAPI) => {
    const id = data.id;
    try {
      const resp = await axios.put(INVOICE + `${id}`, data.data, {
        headers: { Authorization: thunkAPI.getState().user.user.token },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error);
    }
  }
);
export const getInvoice = createAsyncThunk(
  "invoice/getInovice",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.get(INVOICE + `${id}`, {
        headers: { Authorization: thunkAPI.getState().user.user.token },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error);
    }
  }
);
export const markAsPaid = createAsyncThunk(
  "invoice/markAsPaid",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.put(
        INVOICE + `mark-as-paid/${id}`,
        {},

        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const markAsUnPaid = createAsyncThunk(
  "invoice/markAsUnPaid",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.put(
        INVOICE + `mark-as-unpaid/${id}`,
        {},

        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
const InvoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setInvoice(state, { payload }) {
      if (payload) {
        state.invoice = payload;
      } else
        state.invoice = {
          id: "",
          code: "",
          createdAt: "",
          updatedAt: "",
          paymentDate: null,
          isPaid: false,
          orderId: "",
          itmes: [],
        };
    },
    clearInvoice(state) {
      state.invoice = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postInvoice.pending, (state) => {
      state.invoiceIsLoading = true;
    });
    builder.addCase(postInvoice.fulfilled, (state, { payload }) => {
      state.invoice = payload.data;
      state.invoice.temp = true;
      state.invoiceIsLoading = false;
    });
    builder.addCase(postInvoice.rejected, (state, { payload }) => {
      state.invoiceIsLoading = false;
      message.error(payload?.data);
    });
    builder.addCase(updateInvoice.pending, (state) => {
      state.invoiceIsLoading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state, { payload }) => {
      message.success(payload.data);
      state.invoiceIsLoading = false;
    });
    builder.addCase(updateInvoice.rejected, (state, { payload }) => {
      message.error(payload.data);
    });
    builder.addCase(markAsPaid.pending, (staste) => {});
    builder.addCase(markAsPaid.fulfilled, (state, { payload }) => {
      state.invoice.isPaid = true;

      message.success(payload.data);
    });
    builder.addCase(markAsPaid.rejected, (state, { payload }) => {
      message.error(payload);
    });
    builder.addCase(markAsUnPaid.pending, (staste) => {});
    builder.addCase(markAsUnPaid.fulfilled, (state, { payload }) => {
      state.invoice.isPaid = false;

      message.success(payload.data);
    });
    builder.addCase(markAsUnPaid.rejected, (state, { payload }) => {
      message.error(payload);
    });
  },
});
export default InvoiceSlice.reducer;
export const { setInvoice, clearInvoice } = InvoiceSlice.actions;
