import { Checkbox, Tag, SimpleGrid, GridItem, Text } from "@chakra-ui/react";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeState } from "../../features/chat/chatSlice";

function FilterChats({ apply, states2 }) {
  const { states } = useSelector((store) => store.lang);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(changeState(e.target.value));
  };
  return (
    <SimpleGrid
      columns={2}
      padding={"1rem"}
      gap={".2rem"}
      boxShadow={"0px 1px 3px rgba(0, 0, 0, 0.5) inset"}
      borderRadius={"10px"}
      marginBottom={"1rem"}
    >
      {states2.map((item, index) => {
        return (
          <GridItem colSpan={1} key={index}>
            <Tag width={"100%"}>
              <Checkbox
                iconSize={"xs"}
                colorScheme={"gray"}
                size={"sm"}
                p={".2rem"}
                value={index}
                onChange={handleChange}
                isChecked={item === 1}
              >
                <Text fontSize={"smaller"}>{states[index]}</Text>
              </Checkbox>
            </Tag>
          </GridItem>
        );
      })}
    </SimpleGrid>
  );
}

export default FilterChats;
