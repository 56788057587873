import React from "react";
import {
  SimpleGrid,
  GridItem,
  Container,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";

import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Nav from "../components/NavBar";
import Loading from "../components/Loading";

import image from "../assets/images/topography.svg";
import img1 from "../assets/images/thems/ramadan/3.png";
import Footer from "../components/Footer";

import Anouncement from "../components/Anouncement";

import FixedNav from "../components/FixedNav";
import FloatingsControles from "../components/FloatingsControles";
import CreateUser from "./CreateUser";

function MainPage({ contextHolder, notification1, connection }) {
  ///////////////////////////////////////////////////////
  const { user, isLoading } = useSelector((store) => store.user);
  const { theme } = useSelector((store) => store.theme);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode } = useColorMode();
  const backgroundColor = "#DFDBE5";

  if (isLoading) return <Loading />;

  if (!user) {
    return <Navigate to="/register" />;
  }

  return (
    <Container
      pt={"20px"}
      paddingX={"2%"}
      minW={"100%"}
      minH={"100vh"}
      bgColor={colorMode === "dark" ? "#635E6C" : backgroundColor}
      bgImage={theme === 1 ? img1 : image}
      bgSize={"cover"}
    >
      {notification1}
      {contextHolder}
      <SimpleGrid
        columns={12}
        spacing="20px"
        opacity={colorMode === "dark" ? 0.96 : 0.92}
      >
        <GridItem colSpan={12}>
          <Anouncement />
        </GridItem>
        <GridItem
          colSpan={12}
          boxShadow={
            "rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
          }
        >
          <Nav connection={connection} />
        </GridItem>
        <>
          <GridItem
            position={"relative"}
            bg={colorMode === "dark" ? "#424242" : "white"}
            boxShadow={
              "rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
            }
            minH={"100vh"}
            colSpan={12}
            rounded={"lg"}
            p={"1rem"}
          >
            <GridItem
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                top: 0,
              }}
              width={["100%", "100%", "75%", "50%"]}
              colSpan={12}
              padding={".5rem"}
              zIndex={1}
            >
              <FixedNav />
            </GridItem>

            <Outlet />
          </GridItem>

          <GridItem
            colSpan={12}
            boxShadow={
              "rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
            }
          >
            {" "}
            <Footer />
          </GridItem>
        </>
        )
      </SimpleGrid>

      {user.role === "Admin" && <FloatingsControles onOpen={onOpen} />}
      <CreateUser isOpen={isOpen} onClose={onClose}></CreateUser>
    </Container>
  );
}

export default MainPage;
