import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  Box,
} from "@chakra-ui/react";
import { FaEllipsisVertical } from "react-icons/fa6";
import React, { useState } from "react";
import Answer from "./Answer";
import { useDispatch } from "react-redux";
import { generateUUID } from "../../utils/Tools";
import {
  addAnswer,
  addQuestion,
  deleteTheTRee,
  buildTheUi,
  deleteFromTheProduct,
} from "../../features/products/ProductSlice";

function Questions({ item, num, update, productId }) {
  const [type, setType] = useState(1);
  const getTheType = (e) => {
    setType(+e);
  };

  const [qnameChanged, setQNameChanged] = useState(false);
  const [qEng, setQEng] = useState(item.value.en);
  const [qGer, setQGer] = useState(item.value.de);
  const qEngHandler = (e) => {
    setQNameChanged(true);
    setQEng(e.target.value);
  };
  const qGerHandler = (e) => {
    setQNameChanged(true);
    setQGer(e.target.value);
  };
  const dispatch = useDispatch();
  const addAnswerHandler = (justSave) => {
    const ch = qnameChanged && item.thisIsNew === undefined;
    const question = {
      id: item.id,
      value: { en: qEng.trim(), de: qGer.trim() },
      parentID: item.parentID,
      children: [],
      type: type,
      thisIsNew: item.thisIsNew,
      isEdited: item.isEdited || ch,
    };
    const answer = {
      id: generateUUID(),
      value: { en: "", de: "" },
      parentID: item.id,
      children: [],
      thisIsNew: true,
    };

    dispatch(addQuestion(question));
    if (justSave === false) {
      dispatch(addAnswer(answer));
    }

    dispatch(buildTheUi());
  };
  const deleteHandler = () => {
    dispatch(deleteTheTRee({ id: productId, item: item.id }));

    if (update && item.thisIsNew !== true) {
      dispatch(deleteFromTheProduct({ id: item.id, type: 0 }));
    }
    dispatch(buildTheUi());
  };
  return (
    <Flex>
      <Flex
        minW={"480px"}
        boxShadow={"sm"}
        flexDir={"column"}
        width={"100%"}
        gap={"1rem"}
        p={"1rem"}
      >
        <InputGroup>
          <InputLeftAddon fontSize={"small"}>{num}</InputLeftAddon>
          <Input
            defaultValue={qEng}
            onChange={qEngHandler}
            placeholder="question"
          ></Input>
        </InputGroup>
        <InputGroup>
          <InputLeftAddon fontSize={"small"}>{num}</InputLeftAddon>
          <Input
            defaultValue={qGer}
            onChange={qGerHandler}
            placeholder="Frage
"
          ></Input>
        </InputGroup>
        <Flex flexDir={"column"} gap={".5rem"}>
          {item.children.map((answer, index) => {
            return (
              <Answer
                key={index}
                num={`${num}-A${index + 1}`}
                item={answer}
                update={update}
                productId={productId}
              />
            );
          })}
        </Flex>
      </Flex>
      <Box paddingTop={"1rem"}>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisVertical />}
            variant="unstile"
          />
          <MenuList zIndex={10000}>
            <MenuItem
              isDisabled={qEng === "" || qGer === ""}
              onClick={() => {
                addAnswerHandler(true);
              }}
            >
              Save
            </MenuItem>
            <MenuItem
              isDisabled={qEng === "" || qGer === ""}
              onClick={() => {
                addAnswerHandler(false);
              }}
            >
              Add Answer
            </MenuItem>
            <MenuItem onClick={deleteHandler}>Delete</MenuItem>
            <MenuDivider />
            <MenuOptionGroup onChange={getTheType}>
              <MenuItemOption value="1">Single</MenuItemOption>
              <MenuItemOption value="2">multi</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

export default Questions;
