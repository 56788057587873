import { IconButton } from "@chakra-ui/react";

import React from "react";
import { GiBugleCall } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { deleteAd } from "../features/Ad";
import BtnLoading from "./BtnLoading";
import NewText from "../pages/NEwText";

import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

function Anouncement() {
  const { ad, isLoading } = useSelector((store) => store.ad);
  if (isLoading) return <BtnLoading />;
  if (ad === undefined) return <></>;
  return <BasicUsage content={<NewText rawContent={ad} />} />;
}

export default Anouncement;

function BasicUsage({ content }) {
  const { id } = useSelector((store) => store.ad);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  return (
    <>
      {user.role !== "Clinic" && (
        <IconButton
          icon={<GiBugleCall size={"2rem"} color={"#5E1675"} />}
          onClick={onOpen}
          colorScheme="purple"
          variant={"outline"}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody minH={"25vh"}>{content}</ModalBody>
          {user.role === "Admin" && (
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  dispatch(deleteAd(id));
                }}
              >
                Remove
              </Button>
            </ModalFooter>
          )}{" "}
        </ModalContent>
      </Modal>
    </>
  );
}
