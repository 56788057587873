import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

import { BASEURL } from "../../utils/urls";
import { getTheError } from "../../utils/ErorrHandling";
const initialState = {
  isLoading: false,
  messages: [],
  chats: [],
  pages: 1,
  states: [1, 1, 1, 1, 1, 1, 1],
  itemPerPage: 20,
  id: undefined,
  activePage: 100, //this for sidebar active tab
  total: 0,
  isThisChatIsOpen: false,
  isTyping: false,
  state: false,
  to: undefined,
  navigateOutside: false,
};

export const getMessages = createAsyncThunk(
  "chat/getMessages",
  async (data, thunkAPI) => {
    try {
      let url =
        thunkAPI.getState().user.user.role !== "Clinic"
          ? `${BASEURL}/api/chat/get-chat/${data.chatId}/${data.clinic}`
          : `${BASEURL}/api/chat/get-my-chat/${data.chatId}`;

      const resp = await axios.get(url, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getChats = createAsyncThunk(
  "chat/getChats",
  async (data, thunkAPI) => {
    try {
      let url =
        thunkAPI.getState().user.user.role === "Clinic"
          ? `${BASEURL}/api/chat/get-my-chats?itemPerPage=${data.itemPerPage}&page=${data.page}`
          : `${BASEURL}/api/chat/get-chats?itemPerPage=${data.itemPerPage}&page=${data.page}`;
      const resp = await axios.get(url, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      return resp.data;
    } catch (error) {
      return;
    }
  }
);

export const getChatsFiltered = createAsyncThunk(
  "chat/getChatsFiltered",
  async (data, thunkAPI) => {
    try {
      let states = thunkAPI.getState().chat.states;
      let url =
        thunkAPI.getState().user.user.role === "Clinic"
          ? `${BASEURL}/api/chat/get-my-chats-filter?itemPerPage=${data.itemPerPage}&page=${data.page}`
          : `${BASEURL}/api/chat/get-filters-chats?itemPerPage=${data.itemPerPage}&page=${data.page}`;
      if (
        thunkAPI.getState().user.user.role === "Accountant" ||
        thunkAPI.getState().user.accountant
      ) {
        states = [0, 0, 0, 0, 1, 0, 0];
      }
      let tempUrl = "";
      for (let i = 0; i < states.length; i++) {
        if (states[i] === 0) {
          tempUrl += `&states=${i}`;
        }
      }
      const resp = await axios.get(url + tempUrl, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      return resp.data;
    } catch (error) {
      return;
    }
  }
);
export const markAsUnread = createAsyncThunk(
  "chat/markAsUnread",
  async (data, thunkAPI) => {
    try {
      let url = `${BASEURL}/api/chat/mark-as-unread/${data}`;

      const resp = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return;
    }
  }
);
export const markAsRead = createAsyncThunk(
  "chat/markAsRead",
  async (data, thunkAPI) => {
    try {
      let url = `${BASEURL}/api/chat/mark-all-as-read`;

      const resp = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return;
    }
  }
);
export const createChat = createAsyncThunk(
  "chat/createChat",
  async (data, thunkAPI) => {
    try {
      let url = `${BASEURL}/api/chat/create-chat/${data.id}?title=${data.mRecord}`;

      const resp = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return;
    }
  }
);
export const checkIfThiChatIsOpen2 = createAsyncThunk(
  "chat/checkIfThiChatIsOpen2",
  (data, thunkAPI) => {
    if (
      thunkAPI.getState().chat.activePage !== 5 ||
      thunkAPI.getState().chat.id !== data.chatId
    ) {
      const open = data?.open;
      open(data?.content, data?.title, data?.chatId, data?.clinicId);
    }
  }
);

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    changeState(state, { payload }) {
      if (state.states[payload] === 1) state.states[payload] = 0;
      else state.states[payload] = 1;
    },
    navigateOut(state) {
      state.navigateOutside = false;
    },
    unreadChats(state, { payload }) {
      state.total = payload;
    },
    checkIfThiChatIsOpen(state, { payload }) {
      if (state.activePage !== 5 || payload?.chatId !== state.id) {
      }
    },
    addMessage(state, { payload }) {
      if (state.id === payload?.chatId) {
        state.messages = [...state.messages, payload];
      }
    },
    removeData(state) {
      state.messages = [];
      state.id = undefined;
    },
    setActivePage(state, { payload }) {
      state.activePage = payload;

      if (payload === 5) {
        state.isThisChatIsOpen = true;
      } else {
        state.isThisChatIsOpen = false;
        state.id = undefined;
        state.isTyping = false;
      }
    },
    setId(state, { payload }) {
      state.id = payload;
    },

    changeTyping(state, { payload }) {
      state.state = payload?.state;
      state.to = payload?.chatId;
      if (payload?.chatId === state.id) {
        state.isTyping = payload?.state;
      }
    },
    updateMessages(state, { payload }) {
      if (state.id === payload) {
        for (let i = state.messages.length - 1; i >= 0; i--) {
          if (state.messages[i].isViewed === true) break;

          state.messages[i].isViewed = true;
        }
      }
    },
    handleChange: (state, { payload }) => {
      state[payload?.name] = payload?.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMessages.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      state.id = payload?.data.id;
      state.messages = payload?.data.messages;
      state.title = payload?.data.title;
      state.orderId = payload?.data.orderId;
    });
    builder.addCase(getMessages.rejected, (state, { payload }) => {
      message.error(payload);
      state.isLoading = false;
    });
    builder.addCase(createChat.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createChat.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      message.success("success");
    });
    builder.addCase(createChat.rejected, (state, { payload }) => {
      message.error(payload);
      state.isLoading = false;
    });

    builder.addCase(getChatsFiltered.pending, (state) => {
      state.chats = [];
      state.isLoading = true;
    });
    builder.addCase(getChatsFiltered.fulfilled, (state, { payload }) => {
      state.chats = [];
      state.isLoading = false;

      state.pages = payload?.data.pages;
      state.chats = payload?.data.chats;
    });
    builder.addCase(getChatsFiltered.rejected, (state, { payload }) => {
      state.chats = [];
      message.error(payload);
      state.isLoading = false;
    });
    builder.addCase(getChats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getChats.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.pages = payload?.data.pages;
      state.chats = payload?.data.chats;
    });
    builder.addCase(getChats.rejected, (state, { payload }) => {
      message.error(payload);
      state.isLoading = false;
    });
    builder.addCase(markAsUnread.pending, (state) => {});
    builder.addCase(markAsUnread.fulfilled, (state, { payload }) => {
      message.success(payload?.data);
    });
    builder.addCase(markAsUnread.rejected, (state, { payload }) => {
      message.error(payload);
    });
    builder.addCase(markAsRead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(markAsRead.fulfilled, (state, { payload }) => {
      state.total = 0;
      message.success(payload?.data);
      state.navigateOutside = true;
      state.isLoading = false;
    });
    builder.addCase(markAsRead.rejected, (state, { payload }) => {
      message.error(payload);
      state.isLoading = false;
    });
  },
});

export default ChatSlice.reducer;
export const {
  addMessage,
  removeData,
  setActivePage,
  checkIfThiChatIsOpen,
  setId,
  setIsTyping,
  changeTyping,
  updateMessages,
  unreadChats,
  handleChange,
  changeState,
  navigateOut,
} = ChatSlice.actions;
