import React, { useState } from "react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";

import {
  Text,
  Flex,
  IconButton,
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import { LiaQuestionSolid } from "react-icons/lia";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

function ProductName({ item, toggleActivation }) {
  const { colorMode } = useColorMode();
  const { allerts, language } = useSelector((store) => store.lang);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="22vh"
    >
      <Flex
        flexDir={"column"}
        position="relative"
        width="150px"
        height="150px"
        rounded={"md"}
        // bgImg={img}
        boxShadow={"md"}
        outline={"1px solid gray"}
      >
        <IconButton
          icon={item.isActive ? <CloseIcon /> : <CheckIcon />}
          onClick={onOpen}
          variant={"unset"}
          w={"fit-content"}
          justifySelf={"end"}
          alignSelf={"end"}
        />

        <Text
          p={"1rem"}
          fontSize="md"
          w={"100%"}
          fontWeight="semibold"
          textAlign={"center"}
          position="absolute"
          top="20px"
          left="50%"
          transform="translateX(-50%)"
          zIndex="10"
        >
          {language === "English" ? item.name["en"] : item.name["de"]}
        </Text>
        <Flex
          justifySelf={"center"}
          alignSelf={"center"}
          p={".4rem"}
          rounded={"md"}
          width={"90%"}
          bgColor={colorMode === "dark" ? "#BB8493" : "#F2F1EB"}
          pos={"absolute"}
          bottom={"10px"}
          alignItems={"center"}
          justifyContent={"space-around"}
          gap={".5rem"}
        >
          <Flex
            fontWeight={"semibold"}
            alignItems={"center"}
            fontSize={"1rem"}
            gap={".1rem"}
          >
            <LiaQuestionSolid />
            <Text>{item.questions.length}</Text>
          </Flex>
          <Flex
            fontWeight={"semibold"}
            alignItems={"center"}
            fontSize={"1rem"}
            gap={".1rem"}
          >
            <MdOutlineQuestionAnswer />
            <Text>{item.answers.length}</Text>
          </Flex>
          <Link to={"/update-product"} state={{ item }}>
            <IconButton
              colorScheme={"blue"}
              variant={"link"}
              icon={<FaArrowRight />}
            ></IconButton>
          </Link>
        </Flex>
      </Flex>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{allerts.productHeadr}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{allerts.productMessage}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {allerts.no}
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                toggleActivation(item);
                onClose();
              }}
            >
              {allerts.yes}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
}

export default ProductName;
