import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  InputGroup,
  InputRightElement,
  Text,
  Divider,
  Center,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { loginUser } from "../features/user/UserSlice";
import { Navigate, useNavigate } from "react-router-dom";
import BtnLoading from "../components/BtnLoading";
const initialState = {
  username: "",
  password: "",
  role: "",
};

function Login() {
  const { user, isLoading } = useSelector((store) => store.user);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { login } = useSelector((store) => store.lang);
  const handler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  if (user) return <Navigate to={"/"} />;
  const submitHandler = async () => {
    if (values.username === "") {
    } else if (values.password === "") {
    } else {
      try {
        await dispatch(
          loginUser({
            username: values.username,
            password: values.password,
          })
        ).then((e) => {
          if (e && e.payload && e.payload.respond === "error") {
            const flag = e.payload.state;
            if (flag === "403" || flag === "401") navigate("/403");
            else if (flag === "400") navigate("/faild");
            else if (flag === "404") navigate("/404");
            else if (flag === "500") navigate("/500");
          }
          // } else navigate("/");
        });
      } catch (error) {
        // navigate("/register");
      }
    }
  };

  return (
    <Container
      boxShadow={"md"}
      maxW="lg"
      py={{ base: "12", md: "24" }}
      paddingX={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Center>
            <Heading color={"#A0AEC0"} fontSize={"2xl"}>
              <Text as={"span"} color={"blue.400"} fontWeight={"bold"}>
                S
              </Text>
              olutions{" "}
              <Text as={"span"} color={"blue.400"} fontWeight={"bold"}>
                4 M
              </Text>
              edical{" "}
              <Text as={"span"} color={"blue.400"} fontWeight={"bold"}>
                P
              </Text>
              ractices
            </Heading>
          </Center>
          <Divider />
          {/* <Logo /> */}
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>{login.message}</Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          paddingX={{ base: "4", sm: "10" }}
          bg={{ base: "transparent", sm: "bg.surface" }}
          boxShadow={{ base: "none", sm: "md" }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl isRequired>
                  <FormLabel htmlFor="username">{login.userName}</FormLabel>
                  <Input
                    id="username"
                    type="text"
                    name="username"
                    onChange={handler}
                  />
                </FormControl>
                <FormControl id="password" isRequired>
                  <FormLabel>{login.password}</FormLabel>
                  <InputGroup>
                    <Input
                      name="password"
                      onChange={handler}
                      type={showPassword ? "text" : "password"}
                    />
                    <InputRightElement h={"full"}>
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Stack>

              <Stack spacing="6">
                {isLoading ? (
                  <BtnLoading />
                ) : (
                  <Button type="submit">{login.signIn}</Button>
                )}
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Container>
  );
}

export default Login;
