import { logOut } from "../features/user/UserSlice";
export const getTheError = function (error, thunkAPI) {
  if (error.response.status === 401 || error.response.status === 403) {
    thunkAPI.dispatch(logOut());
    return thunkAPI.rejectWithValue(
      thunkAPI.getState().lang.error.sessionExpired
    );
  }
  if (
    !error.response ||
    !error.response.data ||
    !error.response.data.message ||
    error.response.data.code === "A7DF"
  ) {
    return thunkAPI.rejectWithValue(thunkAPI.getState().lang.error.serverError);
  }
  return thunkAPI.rejectWithValue(error.response.data.message);
};
