import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { BASEURL } from "../../utils/urls";
import { createChat } from "../chat/chatSlice";
import { getOrders } from "../allOrders/allOrdersSlice";

import { getTheError } from "../../utils/ErorrHandling";

const initialState = {
  isLoading: false,
  loaded: 0,
  total: 0,
  isUploading: false,
  isDownloading: false,
  invoiceIsLoading: false,
  dArray: [
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
    { isLoading: false, loaded: 0 },
  ],
  order: {
    chatId: null,
    id: "",
    state: 0,
    isUrgent: true,
    note: "",
    adminNote: "",
    patient: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      medicalRecordNumber: "",
    },
    product: [],
    answerIds: [],
    clinic: {
      Id: 0,
      Name: "",
      UserName: "",
      DoctorName: "",
    },
    Histories: [],
    imageFiles: [],
    alginerLower: 0,
    alginerUpper: 0,
    hasAligner: false,
    invoices: [],
    hasInvoice: false,
  },
  updatedData: {
    order: {
      patientInfo: {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        medicalRecordNumber: "",
      },
      ids: [],
      isUrgent: false,
      note: "",
    },
    history: {
      updateDescription: "",
    },
  },
  myUpdatedData: {
    order: {
      patientInfo: {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        medicalRecordNumber: "",
      },
      ids: [],
      isUrgent: false,
      note: "",
    },
  },
  createdSuccessfuly: false,
};
export const postOrderForClinic = createAsyncThunk(
  "order/postOrderForClinic",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.postForm(
        `${BASEURL}/api/order/createorder/clinic/${data.second.id}`,
        data.first,
        {
          timeout: 60 * 60 * 1000,
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
          onUploadProgress: (e) => {
            thunkAPI.dispatch(
              updateProgress({ loaded: e.loaded, total: e.total })
            );
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

export const postOrder = createAsyncThunk(
  "order/postOrder",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.postForm(
        `${BASEURL}/api/order/createorder`,
        data,

        {
          // keepalive: true,
          timeout: 60 * 60 * 1000,

          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: thunkAPI.getState().user.user.token,
          },
          onUploadProgress: (e) => {
            thunkAPI.dispatch(
              updateProgress({ loaded: e.loaded, total: e.total })
            );
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

export const uploadFiles = createAsyncThunk(
  "order/uploadFiles",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(`${BASEURL}/api/order/uploadimages`, data, {
        timeout: 60 * 60 * 1000,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: thunkAPI.getState().user.user.token,
        },
        onUploadProgress: (e) => {
          thunkAPI.dispatch(
            updateProgress({ loaded: e.loaded, total: e.total })
          );
        },
      });

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const UpdateFiles = createAsyncThunk(
  "order/updateFiles",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${BASEURL}/api/order/updatemyorderfiles/` + data.id,
        data.form,
        {
          timeout: 60 * 60 * 1000,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: thunkAPI.getState().user.user.token,
          },
          onUploadProgress: (e) => {
            thunkAPI.dispatch(
              updateProgress({ loaded: e.loaded, total: e.total })
            );
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const AdminUpdateFiles = createAsyncThunk(
  "order/AdminupdateFiles",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${BASEURL}/api/order/updateorderfiles/` + data.id,
        data.form,
        {
          timeout: 60 * 60 * 1000,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: thunkAPI.getState().user.user.token,
          },
          onUploadProgress: (e) => {
            thunkAPI.dispatch(
              updateProgress({ loaded: e.loaded, total: e.total })
            );
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getOrderFromNotification = createAsyncThunk(
  "order/getOrderFromNotification",
  async (data, thunkAPI) => {
    try {
      let url =
        thunkAPI.getState().user.user.role === "Clinic"
          ? `${BASEURL}/api/order/getmyorder/`
          : `${BASEURL}/api/order/getorder/`;
      const resp = await axios.get(
        url + data.id + "/notification/" + data.notificationId,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return { data: resp.data, notificationId: data.notificationId };
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const getOrder = createAsyncThunk(
  "order/getOrder",
  async (id, thunkAPI) => {
    try {
      let url =
        thunkAPI.getState().user.user.role === "Clinic"
          ? `${BASEURL}/api/order/getmyorder/`
          : `${BASEURL}/api/order/getorder/`;
      const resp = await axios.get(url + id, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async (data, thunkAPI) => {
    try {
      let url =
        thunkAPI.getState().user.user.role === "Clinic"
          ? `${BASEURL}/api/order/updatemyorder/`
          : `${BASEURL}/api/order/updateorder/`;
      let order =
        thunkAPI.getState().user.user.role === "Clinic"
          ? data.order.order
          : data.order;
      const resp = await axios.put(url + data.id, order, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      let ids = [];
      let products = [];
      let answers = [];
      if (thunkAPI.getState().user.user.role === "Clinic") {
        ids = [...order.ids];
        answers = [...order.answerIds];
      } else {
        ids = [...order.order?.ids];
        answers = [...order?.order?.answerIds];
      }
      products = thunkAPI
        .getState()
        .products.products.filter((e) => {
          return ids.includes(e.id);
        })
        .map((e) => {
          return { product: e };
        });

      thunkAPI.dispatch(updateProductInTheOrder({ products, answers }));

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const updateAdminNote = createAsyncThunk(
  "order/updateAdminNote",
  async (data, thunkAPI) => {
    try {
      let url = `${BASEURL}/api/order/admin-note/`;

      const resp = await axios.put(url + data.id, data.note, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      return { data: data.note, msg: resp.data };
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const updateAligner = createAsyncThunk(
  "order/updateAligner",
  async (data, thunkAPI) => {
    try {
      let url = `${BASEURL}/api/order/update-Alginer/${data.id}/${data.count2}/${data.count1}`;

      const resp = await axios.put(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
let curState = 0;
export const updateState = createAsyncThunk(
  "order/updateState",
  async (data, thunkAPI) => {
    try {
      curState = data.state;

      const resp = await axios.put(
        `${BASEURL}/api/order/changeorderstate/${data.id}?state=${data.state}&clinicId=${data.clinicId}`,
        { updateDescription: data.updateDescription },
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async (id, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${BASEURL}/api/order/deleteorder/${id}`,
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const deleteFile = createAsyncThunk(
  "order/deleteFile",
  async (fileName, thunkAPI) => {
    try {
      const arr = [fileName];

      const resp = await axios.delete(
        `${BASEURL}/api/order/deleteimages`,
        // { arr },
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
          data: arr,
        }
      );

      let images = thunkAPI.getState().order.order.imageFiles.filter((item) => {
        return item.fileName != fileName;
      });

      return { data: resp.data, images: images };
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const exportFile = createAsyncThunk(
  "order/exportFile",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/order/downloadfiles?${_.fileName}&name=${
          _.name === undefined ? "download" : _.name
        }`,
        {
          timeout: 10 * 60 * 1000,
          responseType: "blob",
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
          onDownloadProgress: (e) => {
            thunkAPI.dispatch(downLoadProgress(e.loaded));
          },
        }
      );

      const contentDispositionHeader = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const defaultFilename =
        matches && matches[1]
          ? matches[1].replace(/['"]/g, "")
          : "downloaded_file";

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      // Check if the response contains a specific content type for a zip file
      const isZipFile = response.headers["content-type"]
        .toLowerCase()
        .includes("application/zip");

      // Modify the filename and download attribute accordingly
      const filename = isZipFile ? defaultFilename : "downloaded_file";

      link.href = url;
      link.setAttribute("download", defaultFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return { success: true };
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

export const downloadFile = createAsyncThunk(
  "order/downloadFile",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/order/download/${_.fileName}`,
        {
          timeout: 10 * 60 * 1000,
          responseType: "arraybuffer",
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
          onDownloadProgress: (e) => {
            thunkAPI.dispatch(
              downLoadProgress({
                loaded: e.loaded,
                index: _.index,
                isLoading: true,
              })
            );
          },
        }
      );
      const blob = new Blob([response.data]);
      const link = document.createElement("a");

      // Set the desired filename
      const filename = `${_.fName} ${_.lName} ${_.displayName}`; // Replace with your desired filename
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link element
      document.body.removeChild(link);
      thunkAPI.dispatch(
        downLoadProgress({
          loaded: 0,
          index: _.index,
          isLoading: false,
        })
      );

      return { success: true };
    } catch (error) {
      thunkAPI.dispatch(
        downLoadProgress({
          loaded: 0,
          index: _.index,
          isLoading: false,
        })
      );

      return getTheError(error, thunkAPI);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateProductInTheOrder(state, { payload }) {
      state.order.answerIds = payload.answers;
      state.order.product = payload.products;
    },
    createdSuccessfuly(state, { payload }) {
      state.createdSuccessfuly = payload;
    },
    castUpdatedData(state) {
      state.updatedData.order.patientInfo.firstName =
        state.order.patient.firstName;
      state.updatedData.order.patientInfo.lastName =
        state.order.patient.lastName;
      state.updatedData.order.patientInfo.dateOfBirth =
        state.order.patient.dateOfBirth;
      state.updatedData.order.patientInfo.medicalRecordNumber =
        state.order.patient.medicalRecordNumber;
      state.updatedData.order.isUrgent = state.order.isUrgent;
      state.updatedData.order.note = state.order.note;
      state.updatedData.order.ids = state.order.product.map(
        (item) => item.product.id
      );
    },
    castMyUpdatedData(state) {
      state.myUpdatedData.order.patientInfo.firstName =
        state.order.patient.firstName;
      state.myUpdatedData.order.patientInfo.lastName =
        state.order.patient.lastName;
      state.myUpdatedData.order.patientInfo.dateOfBirth =
        state.order.patient.dateOfBirth;
      state.myUpdatedData.order.patientInfo.medicalRecordNumber =
        state.order.patient.medicalRecordNumber;
      state.myUpdatedData.order.isUrgent = state.order.isUrgent;
      state.myUpdatedData.order.note = state.order.note;
      state.myUpdatedData.order.ids = state.order.product.map(
        (item) => item.product.id
      );
    },
    updateName(state, { payload }) {
      state.updatedData.order.patientInfo.firstName = payload?.name;
      state.updatedData.history.updateDescription = payload?.description;
      state.order.patient.firstName = payload?.name;
    },
    updateName2(state, { payload }) {
      state.updatedData.order.patientInfo.lastName = payload?.name;
      state.updatedData.history.updateDescription = payload?.description;
      state.order.patient.lastName = payload?.name;
    },
    updateMedicalRecord(state, { payload }) {
      state.updatedData.order.patientInfo.medicalRecordNumber =
        payload?.MedicalId;
      state.updatedData.history.updateDescription = payload?.description;
      state.order.patient.medicalRecordNumber = payload?.MedicalId;
    },
    updateBirthDate(state, { payload }) {
      state.updatedData.order.patientInfo.dateOfBirth = payload?.BirthDate;
      state.updatedData.history.updateDescription = payload?.description;
      state.order.patient.dateOfBirth = payload?.BirthDate;
    },
    updateProduct(state, { payload }) {
      state.updatedData.order.ids = payload?.products.map((item) => {
        return item.id;
      });
      state.updatedData.history.updateDescription = payload?.description;
      state.order.product = [...payload?.products];
    },
    clearOrder(state) {
      state.order.chatId = null;
    },
    updateProgress(state, { payload }) {
      state.loaded = Math.ceil(payload?.loaded / (1024 * 1024));
      state.total = Math.ceil(payload?.total / (1024 * 1024));
    },
    downLoadProgress(state, { payload }) {
      state.dArray[payload?.index].isLoading = payload?.isLoading;
      state.dArray[payload?.index].loaded = Math.ceil(
        payload?.loaded / (1024 * 1024)
      );
      state.dArray[payload?.index].isLoading = payload?.isLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state) => {
      state.order.id = 1;
    });
    builder.addCase(UpdateFiles.pending, (state) => {
      state.isUploading = true;
    });
    builder.addCase(UpdateFiles.fulfilled, (state, { payload }) => {
      state.order.imageFiles = [...state.order.imageFiles, ...payload?.data];
      state.isUploading = false;
      message.success("success");
    });
    builder.addCase(UpdateFiles.rejected, (state, { payload }) => {
      state.isUploading = false;
      message.error(payload);
    });
    builder.addCase(AdminUpdateFiles.pending, (state) => {
      state.isUploading = true;
    });
    builder.addCase(AdminUpdateFiles.fulfilled, (state, { payload }) => {
      state.order.imageFiles = [...state.order.imageFiles, ...payload?.data];

      state.isUploading = false;
      message.success("success");
    });
    builder.addCase(AdminUpdateFiles.rejected, (state, { payload }) => {
      state.isUploading = false;
      message.error(payload);
    });

    builder.addCase(postOrder.pending, (state) => {
      state.isUploading = true;
    });
    builder.addCase(postOrder.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.createdSuccessfuly = true;
    });
    builder.addCase(postOrder.rejected, (state, { payload }) => {
      state.isUploading = false;

      message.error(payload);
    });
    builder.addCase(getOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload && payload?.data) {
        state.order.chatId = payload?.data.chatId;

        state.order = payload?.data;
      }
    });
    builder.addCase(getOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(getOrderFromNotification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getOrderFromNotification.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;

        if (payload && payload?.data.data) {
          state.order = payload?.data.data.order;
        }
      }
    );
    builder.addCase(getOrderFromNotification.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });

    builder.addCase(updateOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.data.hasAligner === false) {
        state.order.alginerLower = 0;
        state.order.alginerUpper = 0;
      }
      state.order.hasAligner = payload.data.hasAligner;

      state.order.state = 0;

      message.success("success");

      castMyUpdatedData();
      castUpdatedData();
    });
    builder.addCase(updateOrder.rejected, (state, { payload }) => {
      state.isLoading = false;

      message.error(payload);
    });
    builder.addCase(updateAdminNote.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAdminNote.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order.adminNote = payload?.data;
      message.success(payload?.msg.data);
    });
    builder.addCase(updateAdminNote.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });

    builder.addCase(updateState.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateState.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order.state = curState;
      message.success(payload?.data);
    });
    builder.addCase(updateState.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(updateAligner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAligner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order.alginerLower = payload.data.alginerLower;
      state.order.alginerUpper = payload.data.alginerUpper;

      message.success("success");
    });
    builder.addCase(updateAligner.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(deleteOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order.id = undefined;
      message.success(payload?.data);
    });
    builder.addCase(deleteOrder.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(deleteFile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order.imageFiles = payload.images;
      message.success(payload?.data.data);
    });
    builder.addCase(deleteFile.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(exportFile.pending, (state) => {
      state.isDownloading = true;
    });
    builder.addCase(exportFile.fulfilled, (state, { payload }) => {
      state.isDownloading = false;
      message.success("success");
    });
    builder.addCase(exportFile.rejected, (state, { payload }) => {
      state.isDownloading = false;
      message.error(payload);
    });
    builder.addCase(downloadFile.pending, (state) => {});
    builder.addCase(downloadFile.fulfilled, (state, { payload }) => {
      message.success("success");
    });
    builder.addCase(downloadFile.rejected, (state, { payload }) => {
      message.error(payload);
    });

    builder.addCase(postOrderForClinic.pending, (state) => {
      state.isUploading = true;
    });
    builder.addCase(postOrderForClinic.fulfilled, (state, { payload }) => {
      state.isUploading = false;
      state.createdSuccessfuly = true;
      message.success("success");
    });
    builder.addCase(postOrderForClinic.rejected, (state, { payload }) => {
      state.isUploading = false;
      message.error(payload);
    });
    builder.addCase(createChat.fulfilled, (state, { payload }) => {
      state.order.chatId = payload?.data.id;
    });
  },
});

export default orderSlice.reducer;
export const {
  updateMedicalRecord,
  updateName,
  updateName2,
  castMyUpdatedData,
  castUpdatedData,
  updateBirthDate,
  updateProduct,
  clearOrder,
  createdSuccessfuly,
  updateProgress,
  downLoadProgress,
  updateProductInTheOrder,
} = orderSlice.actions;
