import {
  Flex,
  Badge,
  Card,
  Checkbox,
  Text,
  Spacer,
  Tooltip,
  Button as btn,
} from "@chakra-ui/react";
import { Button, ConfigProvider, Space, Table, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { colors, icons } from "../utils/Tools";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
function OrderCard({ order, handler, CheckboxIsVisible = true }) {
  const { user, accountant } = useSelector((store) => store.user);
  const {
    orderType,
    roleText,
    orderLabels,
    heading,
    language,
    filters,
    buttonsText,
  } = useSelector((store) => store.lang);
  let state2 = order.state;
  let utcTime = new Date(order.createdDate);
  let timezoneOffset = new Date().getTimezoneOffset();

  // Convert UTC time to local time
  let localTime = new Date(utcTime.getTime() - timezoneOffset * 60 * 1000);
  const dateObject = new Date(localTime);

  const formattedDate = dateObject.toLocaleDateString("en-GB");

  // Format the time as "hh:mm a"
  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
    },
  ];
  const items = order.product?.map((e, index) => {
    return {
      key: index,
      label: language === "English" ? e?.product?.name.en : e?.product?.name.de,
    };
  });
  const data = [
    {
      key: "1",
      title: <Text fontSize={"small"}> {roleText.clinic}</Text>,
      data: <Text fontSize={"small"}> {order.clinic.Name}</Text>,
    },
    {
      key: "2",
      title: <Text fontSize={"small"}> {orderLabels.firstName}</Text>,
      data: <Text fontSize={"small"}> {order.patient.firstName}</Text>,
    },
    {
      key: "3",
      title: <Text fontSize={"small"}> {orderLabels.lastName}</Text>,
      data: <Text fontSize={"small"}> {order.patient.lastName}</Text>,
    },
    {
      key: "4",
      title: <Text fontSize={"small"}> {orderLabels.id}</Text>,
      data: (
        <Text fontSize={"small"}> {order.patient.medicalRecordNumber}</Text>
      ),
    },
    {
      key: "5",
      title: <Text fontSize={"small"}> {orderLabels.birthDate}</Text>,
      data: <Text fontSize={"small"}> {order.patient.dateOfBirth}</Text>,
    },
    {
      key: "6",
      title: <Text fontSize={"small"}> {heading.theProducts}</Text>,
      data:
        order.product.length > 1 ? (
          <Dropdown menu={{ items }}>
            <Button>
              <Space>
                {orderLabels.products}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        ) : (
          <Tooltip
            placement="top"
            label={
              language === "English"
                ? order.product[0]?.product?.name.en
                : order.product[0]?.product?.name.de
            }
          >
            <Tag
              key={order?.product[0]?.product.id}
              style={{
                fontSize: 14,
              }}
              color="geekblue"
            >
              {language === "English"
                ? order?.product[0]?.product.name.en
                : order?.product[0]?.product.name.de}
            </Tag>
          </Tooltip>
        ),
    },
  ];

  return (
    <Card
      // zIndex={1}
      transform="scale(0.95)"
      transition={"all ease .5s"}
      cursor={"pointer"}
      _hover={{
        transform: "scale(1)",
      }}
      width={300}
      borderBottom={`5px solid ${colors[state2]}`}
      position="relative" // Ensure this is relative
      zIndex={1} // Lower zIndex for the Card
    >
      <Flex
        padding={".5rem"}
        paddingBottom={".7rem"}
        flexDir={"column"}
        minH={"15rem"}
        gap={".5rem"}
      >
        <Tag
          style={{
            fontSize: 12,
          }}
          color="magenta"
        >
          {formattedDate + " " + formattedTime}
        </Tag>
        <Flex gap={".2rem"}>
          <Badge
            size={"small"}
            h={"100%"}
            color={
              user.role === "Accountant" || accountant
                ? order.hasInvoice
                  ? "#1E5162"
                  : "#821D30"
                : order.isUrgent
                ? "#821D30"
                : "#1E5162"
            }
            fontSize={"sm"}
            alignSelf={"center"}
            bg={
              user.role === "Accountant" || accountant
                ? order.hasInvoice
                  ? "#D4F1F4"
                  : "#FAE8E0"
                : order.isUrgent
                ? "#FAE8E0"
                : "#D4F1F4"
            }
            textTransform={"none"}
            textAlign="center"
          >
            {user.role === "Accountant" || accountant
              ? order.hasInvoice
                ? filters.hasInvoice
                : filters.hasNoInvoice
              : order.isUrgent
              ? orderType.urgent
              : orderType.normal}
          </Badge>
          {(user.role === "Accountant" || accountant) &&
            order.hasInvoice &&
            (order.isPaid ? (
              <Badge
                color={"green.800"}
                width={"6rem"}
                bg={"green.200"}
                paddingX={"0.75rem"}
                alignSelf={"center"}
                rounded={"full"}
                letterSpacing={"2px"}
                textAlign={"center"}
              >
                {buttonsText.paid}
              </Badge>
            ) : (
              <Badge
                color={"red.800"}
                width={"6rem"}
                bg={"red.200"}
                paddingX={"0.75rem"}
                alignSelf={"center"}
                rounded={"full"}
                letterSpacing={"2px"}
                textAlign={"center"}
              >
                {buttonsText.unPaid}
              </Badge>
            ))}
          <Spacer />

          {user.role === "Accountant" || accountant || icons.regular[state2]}
          <Spacer />

          {user.role !== "Clinic" &&
            user.role !== "Accountant" &&
            CheckboxIsVisible && (
              <Checkbox
                onChange={(e) => {
                  handler(e, order.id, order.clinic.id);
                }}
                defaultChecked
              ></Checkbox>
            )}
        </Flex>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 10,
                cellFontSize: 18,
              },
            },
          }}
        >
          <Table
            style={{
              fontSize: "11.2rem",
            }}
            pagination={false}
            dataSource={data}
            columns={columns}
            showHeader={false}
          />
        </ConfigProvider>
        <Link
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          to={
            user.role === "Accountant" || accountant
              ? "/order-info-acc"
              : "/order-info"
          }
          state={{ id: order.id }}
        >
          <ChevronRightIcon fontSize={"2rem"} />
        </Link>
      </Flex>
    </Card>
  );
}

export default OrderCard;
