import { Flex, Text } from "@chakra-ui/react";

import React from "react";

import { useSelector } from "react-redux";

import RadioAnswers from "./RadioAnswers";
import CheckBoxGroup from "./CheckBoxGroup";

function QuestionsWithoutInput({ item, num }) {
  const { language } = useSelector((state) => state.lang);

  return (
    <Flex>
      <Flex
        minW={"480px"}
        boxShadow={"sm"}
        flexDir={"column"}
        width={"100%"}
        gap={"1rem"}
        p={"1rem"}
      >
        <Text p={".5rem"} fontStyle={"italic"} fontWeight={"semibold"}>
          {language === "English" ? item.value.en : item.value.de}
        </Text>

        <Flex flexDir={"column"} gap={".5rem"}>
          {item.type === 1 ? (
            <RadioAnswers item={item} />
          ) : (
            <CheckBoxGroup item={item}> </CheckBoxGroup>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default QuestionsWithoutInput;
