import { Button, Flex, Text, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAdminNote } from "../../features/order/orderSlice";

function EditAdminNote({ value, id }) {
  const [editable, setEditable] = useState(false);
  const [note, setNote] = useState(value);
  const { controlers } = useSelector((store) => store.lang);
  const dispatch = useDispatch();
  const handler = (e) => {
    setNote(e.target.value);
  };
  const submitHandler = () => {
    dispatch(updateAdminNote({ id: id, note: note }));
    setEditable(false);
  };
  if (editable === false)
    return (
      <Flex w={"100%"} rowGap={"1rem"} direction={"column"}>
        <Text width={"100%"} fontWeight={"semibold"} letterSpacing={"1.1"}>
          {value}
        </Text>
        <Button
          colorScheme="blue"
          width={"20%"}
          onClick={() => setEditable(true)}
        >
          {" "}
          {controlers.edit}
        </Button>
      </Flex>
    );
  else
    return (
      <Flex rowGap={"1rem"} w={"100%"} direction={"column"}>
        <Textarea
          onChange={handler}
          width={"100%"}
          value={note}
          resize={"none"}
        />
        <Button colorScheme="blue" onClick={submitHandler}>
          {controlers.save}
        </Button>
      </Flex>
    );
}

export default EditAdminNote;
