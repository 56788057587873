import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ changeHandler, message }) => {
  return (
    <ReactQuill
      value={message}
      onChange={changeHandler}
      placeholder=""
      modules={{
        toolbar: [
          [{ size: ["small", false, "large", "huge"] }], // Font size options
          [{ list: "ordered" }, { list: "bullet" }],
          ["bold", "italic", "underline"],
          ["link"],
          ["clean"], // remove formatting button
        ],
      }}
      formats={["size", "list", "bold", "italic", "underline", "link"]}
      style={{
        borderRadius: ".6rem",
        height: "fit-content",

        background: "white",
        marginBottom: "2rem",
        width: "100%",
        border: "1px solid #ccc", // Added border here
      }}
    />
  );
};

export default TextEditor;
