import React from "react";
import { Bar } from "react-chartjs-2";
import { useColorMode } from "@chakra-ui/react";
const Bars = ({ contributionData, isInvoice = false }) => {
  const chartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Seb",
      "Oct",
      "Nov",
      "Des",
    ],
    datasets: [
      {
        label: isInvoice ? "Money" : "Orders",

        data: contributionData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.4)",
          "rgba(255, 159, 64, 0.4)",
          "rgba(255, 205, 86, 0.4)",
          "rgba(75, 192, 192, 0.4)",
          "rgba(54, 162, 235, 0.4)",
          "rgba(153, 102, 255, 0.4)",
          "rgba(201, 203, 207, 0.4)",
          "rgba(255, 99, 132, 0.4)",
          "rgba(255, 159, 64, 0.4)",
          "rgba(75, 192, 192, 0.4)",
          "rgba(153, 102, 255, 0.4)",
          "rgba(201, 203, 207, 0.4)",
        ],
      },
    ],
  };
  const { colorMode } = useColorMode();
  return (
    <Bar
      data={chartData}
      options={{
        scales: {
          x: {
            ticks: {
              color: colorMode === "dark" ? "white" : "black",
            },
          },
          y: {
            ticks: {
              color: colorMode === "dark" ? "white" : "black",
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: colorMode === "dark" ? "white" : "black",
            },
          },
          title: {
            display: true,
            color: colorMode === "dark" ? "white" : "black",

            text: isInvoice ? "Money per month" : "order per month",
            padding: {
              top: 10,
              bottom: 30,
            },
          },
        },
      }}
    />
  );
};
export default Bars;
