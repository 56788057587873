import React, { useEffect, useState } from "react";
import ChatControle from "./ChatControle";
import MessageList from "./MessageList";
import { Box, Button, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessages,
  removeData,
  setActivePage,
  setId,
} from "../../features/chat/chatSlice";

import { Link, Navigate, useLocation } from "react-router-dom";
import Loading from "../Loading";
import { logOut } from "../../features/user/UserSlice";

function Chat({ connection }) {
  const location = useLocation();
  const myChat = location?.state?.chatId;
  const clinic = location?.state?.clinicId;

  let {
    messages: curMsgs,
    title,
    orderId,
    isLoading,

    isTyping,
  } = useSelector((store) => store.chat);
  const { user, accountant } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);

  useEffect(() => {
    dispatch(setActivePage(5));
    if (myChat === null || myChat === undefined) {
      return;
    }

    dispatch(removeData());
    dispatch(setId(myChat));

    dispatch(getMessages({ chatId: myChat, clinic }));
  }, [myChat]);

  const { heading } = useSelector((store) => store.lang);
  let [typing, setTyping] = useState("");
  useEffect(() => {
    if (isTyping) setTyping(heading.typing);
    else setTyping("");
  }, [isTyping]);

  const sendMessage = async (content) => {
    try {
      await connection.invoke("SendMessage", myChat, content, clinic, title);
    } catch (e) {}
  };
  const type = async (typing) => {
    try {
      await connection.invoke("Typing", myChat, typing, clinic);
    } catch (e) {}
  };
  const see = async () => {
    try {
      await connection.invoke("Viewing", myChat, clinic);
    } catch (e) {}
  };

  let [msgs, setMsgs] = useState(curMsgs);

  useEffect(() => {
    if (curMsgs.length > 0) {
      if (
        curMsgs[curMsgs.length - 1].owner === 1 &&
        curMsgs[curMsgs.length - 1].isViewed === false
      ) {
        see();
      }
    }
    setMsgs(curMsgs);
  }, [curMsgs]);
  if (isLoading) return <Loading></Loading>;
  if (myChat === undefined || myChat === null) return <Navigate to={"error"} />;
  return (
    <Box p={"1rem"}>
      <Link
        to={
          user.role === "Accountant" || accountant
            ? "/order-info-acc"
            : "/order-info"
        }
        state={{ id: orderId }}
      >
        <Button
          variant={"link"}
          fontSize={"1.4rem"}
          w={"80%"}
          isTruncated
          textOverflow={"ellipsis"}
          colorScheme={"blue"}
          justifyContent={"start"}
        >
          {title}
        </Button>
      </Link>
      <Text p={".6rem"} color={"green.300"}>
        {typing}
      </Text>
      <MessageList messages={msgs} see={see} />
      {user.role !== "Accountant" && (
        <ChatControle type={type} sendMessage={sendMessage} />
      )}
    </Box>
  );
}

export default Chat;
