import React, { useState } from "react";
import Viewer from "react-viewer";
import { FaImages } from "react-icons/fa";
import { Empty } from "antd";
import { useSelector } from "react-redux";

function ImageViewer({ images, isEmpty }) {
  const newImages =
    images !== undefined &&
    images !== null &&
    images.filter((element) => element !== undefined);

  const [visible, setVisible] = useState(false);

  const { empty } = useSelector((store) => store.lang);
  if (newImages && newImages?.length > 0)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn"
          onClick={() => {
            setVisible(true);
          }}
        >
          <FaImages style={{ width: "5rem", height: "5rem" }} />
        </button>
        <Viewer
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          images={newImages}
        />
      </div>
    );
  else if (isEmpty) return <Empty description={empty.empty}></Empty>;
}

export default ImageViewer;
