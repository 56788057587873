import React, { useState } from "react";
import { ConfigProvider, Radio, Timeline } from "antd";
import { Flex, Text } from "@chakra-ui/react";

import { useSelector } from "react-redux";
function HistoryOfEdit() {
  return <App />;
}

export default HistoryOfEdit;
const getDate = (data) => {
  const parsedDate = new Date(data.chanagedAt);

  // Create a formatter with the desired format
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return dateFormatter.format(parsedDate);
};
const App = () => {
  const { Histories } = useSelector((store) => store.order.order);

  const [mode, setMode] = useState("left");
  const onChange = (e) => {
    setMode(e.target.value);
  };
  return (
    <Flex flexDir={"column"} width={"100%"} alignItems={"start"}>
      <Radio.Group
        onChange={onChange}
        value={mode}
        style={{
          marginBottom: 20,
        }}
      >
        <Radio value="left">Left</Radio>
        <Radio value="right">Right</Radio>
        <Radio value="alternate">Alternate</Radio>
      </Radio.Group>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 20,
          },
        }}
      >
        <Timeline
          style={{
            width: "100%",
          }}
          mode={mode}
          items={Histories?.map((item) => {
            return {
              label: getDate(item),
              children: (
                <Text width={"100%"}>
                  {item.staff.username} {item.updateDescription}
                </Text>
              ),
            };
          })}
        />
      </ConfigProvider>
    </Flex>
  );
};
