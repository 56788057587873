import { LuClipboardList } from "react-icons/lu";
import { MdPending, MdPrecisionManufacturing } from "react-icons/md";
import { TbProgress } from "react-icons/tb";
import { FaShippingFast } from "react-icons/fa";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/icons";
export const colors = [
  "rgba(255, 159, 64, 0.6)",
  "rgba(75, 192, 192, 0.6)",
  "rgba(54, 162, 235, 0.6)",
  "rgba(153, 102, 255, 0.6)",
  "rgba(45, 205, 100, 0.6)",

  "rgba(255, 99, 100, 0.6)",
  "#7D8ABC",
];
export const icons = {
  white: [
    <Icon as={MdPending} fontSize={"xl"} color="white" />,

    <Icon as={TbProgress} fontSize={"xl"} color="white" />,
    <Icon as={MdPrecisionManufacturing} fontSize={"xl"} color="white" />,
    <Icon as={FaShippingFast} fontSize={"xl"} color="white" />,
    <CheckIcon fontSize={"xl"} color="white" />,

    <CloseIcon fontSize={"xl"} color="white" />,
    <LuClipboardList color="white" />,
  ],
  regular: [
    <Icon as={MdPending} fontSize={"xl"} color={colors[0]} />,

    <Icon as={TbProgress} fontSize={"xl"} color={colors[1]} />,
    <Icon as={MdPrecisionManufacturing} fontSize={"xl"} color={colors[2]} />,
    <Icon as={FaShippingFast} fontSize={"xl"} color={colors[3]} />,
    <CheckIcon fontSize={"xl"} color={colors[4]} />,

    <CloseIcon fontSize={"xl"} color={colors[5]} />,
    <LuClipboardList color={colors[6]} />,
  ],
};

export function generateUUID() {
  // Generate a random hexadecimal string of length 4
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  // Concatenate four random hexadecimal strings separated by hyphens
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

// Example usage:

export function validateTheProducts(
  products = [],
  allAnswers = [],
  allQuestions = []
) {
  let questions = [];
  for (let index = 0; index < products.length; index++) {
    const element = products[index];

    for (let i = 0; i < element.answers.length; i++) {
      const answer = element.answers[i];
      if (allAnswers.includes(answer.id)) {
        questions.push(answer.parentID);
      }
    }
  }
  allQuestions = allQuestions.filter((e) => {
    return questions.includes(e) === false;
  });
  return allQuestions.length === 0;
}

export function buildAnswersTree(products = [], answers = [], lang) {
  const tree = [];
  for (let i = 0; i < products.length; i++) {
    const p = products[i].product ? products[i].product : products[i];
    let cur = {
      title: lang === "English" ? p?.name?.en : p?.name?.de,
      key: p.id,
      children: [],
    };
    const q = p.questions ? [...p?.questions] : [];
    const ans = p.answers ? [...p?.answers] : [];
    const dfs = (item) => {
      q.forEach((e) => {
        if (e.parentID === item.key && e.value.en !== undefined) {
          const temp = {
            title: lang === "English" ? e.value.en : e.value.de,
            key: e.id,
            children: [],
          };
          dfs(temp);
          item.children.push(temp);
        }
      });
      ans.forEach((e) => {
        if (e.parentID === item.key && answers.includes(e.id) === true) {
          const temp = {
            title: lang === "English" ? e.value.en : e.value.de,
            key: e.id,
            children: [],
          };
          dfs(temp);
          item.children.push(temp);
        }
      });
    };

    q.forEach((ele) => {
      if (ele.parentID === "root" || ele.parentID === null) {
        const temp = {
          title: lang === "English" ? ele.value.en : ele.value.de,
          key: ele.id,
          children: [],
        };
        dfs(temp);
        cur.children.push(temp);
      }
    });
    tree.push(cur);
  }

  return tree;
}

export function convertToInvoice(info, orderId) {
  const data = {
    orderId: orderId,
    items: info,
  };
  return data;
}

// TO DO
export function convertFromInvoice(info) {
  const data = info?.map((item) => {
    return {
      id: item?.id,
      product: item?.product,
      price: item?.Price,
      quantity: item?.Quantity,
      string: item.string,
      total: item?.Price * item?.Quantity,
    };
  });
  return data;
}
