import React, { useState } from "react";
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisVertical } from "react-icons/fa6";
import Questions from "./Questions";
import { useDispatch } from "react-redux";

import { generateUUID } from "../../utils/Tools";
import {
  addQuestion,
  addAnswer,
  buildTheUi,
  deleteTheTRee,
  deleteFromTheProduct,
} from "../../features/products/ProductSlice";
function Answer({ item, num, update, productId }) {
  const dispatch = useDispatch();
  const [AnameChanged, setAnameChanged] = useState(false);
  const [ansEng, setEngAnswer] = useState(item.value.en);
  const [ansGer, setGerAnswer] = useState(item.value.de);
  const answerEngHandler = (e) => {
    setAnameChanged(true);
    setEngAnswer(e.target.value);
  };
  const answerGerHandler = (e) => {
    setAnameChanged(true);
    setGerAnswer(e.target.value);
  };
  const deleteHandler = () => {
    dispatch(deleteTheTRee({ id: productId, item: item.id }));
    if (update && item.thisIsNew !== true) {
      dispatch(deleteFromTheProduct({ id: item.id, type: 1 }));
    }
    dispatch(buildTheUi());
  };
  const addAQuestionHandler = (justSave = false) => {
    const question = {
      id: generateUUID(),
      value: { en: "", de: "" },
      parentID: item.id,
      children: [],
      type: 1,
      thisIsNew: true,
    };
    const answer = {
      id: item.id,
      value: { en: ansEng, de: ansGer },
      parentID: item.parentID,
      children: [],
      thisIsNew: item.thisIsNew,
      isEdited: AnameChanged && item.thisIsNew === undefined ? true : false,
    };

    dispatch(addAnswer(answer));
    if (justSave === false) {
      dispatch(addQuestion(question));
    }

    dispatch(buildTheUi());
  };
  return (
    <Flex mb={".8rem"}>
      <Flex
        marginLeft={"1rem"}
        flexDir={"column"}
        minW={"480px"}
        width={"100%"}
      >
        <InputGroup>
          <InputLeftAddon fontSize={"small"}>{num}</InputLeftAddon>
          <Input
            mb={".5rem"}
            defaultValue={ansEng}
            onChange={answerEngHandler}
            Placeholder={"Answer"}
          ></Input>
        </InputGroup>
        <InputGroup>
          <InputLeftAddon fontSize={"small"}>{num}</InputLeftAddon>
          <Input
            defaultValue={ansGer}
            onChange={answerGerHandler}
            Placeholder={"Answer"}
          ></Input>
        </InputGroup>

        {item.children.map((question, index) => {
          return (
            <Questions
              key={index}
              num={`${num}-Q${index + 1}`}
              item={question}
              update={update}
              productId={productId}
            />
          );
        })}
      </Flex>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FaEllipsisVertical />}
          variant="unstile"
        />
        <MenuList zIndex={10000}>
          <MenuItem
            isDisabled={ansEng === "" || ansGer === ""}
            onClick={() => {
              addAQuestionHandler(true);
            }}
          >
            Save
          </MenuItem>
          <MenuItem
            isDisabled={ansEng === "" || ansGer === ""}
            onClick={() => {
              addAQuestionHandler(false);
            }}
          >
            Add Question
          </MenuItem>
          <MenuItem onClick={deleteHandler}>Delete</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}

export default Answer;
