import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL, STAFF } from "../../utils/urls";
import { getTheError } from "../../utils/ErorrHandling";
import { message } from "antd";
import { activateUser, deActivateUser } from "./UserSlice";
const initialState = {
  isLoading: false,
  staffs: [],
  pages: 1,
  page: 1,
  itemPerPage: 10,
  search: "",
};
export const changepageStaff = createAsyncThunk(
  "staff/changepageStaff",
  async (data, thunkAPI) => {
    try {
      let { search } = thunkAPI.getState().staff;

      const resp = await axios.get(
        STAFF +
          `getstaffs?itemPerPage=${data.itemPerPage}&page=${data.page}` +
          (search !== "" ? `&searchTerm=${search}` : ""),
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      resp.data.data.page = data.page;
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const exportStaff = createAsyncThunk(
  "staff/exportStaff",
  async (_, { getState, thunkAPI }) => {
    try {
      const response = await axios.get(`${BASEURL}/api/staff/getexcelfile`, {
        responseType: "blob",
        headers: {
          Authorization: getState().user.user.token,
        },
      });

      const contentDispositionHeader = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const filename =
        matches && matches[1]
          ? matches[1].replace(/['"]/g, "")
          : "downloaded_file.xlsx";

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return { success: true };
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
const allStaffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    handleChange: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    applyFilters: (state, { payload }) => {
      state.search = payload.search;
    },
    changeActiavtion: (state, { id }) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(changepageStaff.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changepageStaff.fulfilled, (state, { payload }) => {
      if (payload !== undefined && payload.data !== undefined) {
        state.pages = payload.data.pages;
        state.page = payload.data.page;
        state.staffs = payload.data.staffs;
      }
      state.isLoading = false;
    });
    builder.addCase(changepageStaff.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(exportStaff.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(exportStaff.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(exportStaff.rejected, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(deActivateUser.fulfilled, (state, { payload }) => {
      state.staffs = state.staffs.map((item) => {
        if (item.userId === payload) item.isActive = false;
        return item;
      });
    });
    builder.addCase(activateUser.fulfilled, (state, { payload }) => {
      state.staffs = state.staffs.map((item) => {
        if (item.userId === payload) item.isActive = true;
        return item;
      });
    });
  },
});
export const { applyFilters, handleChange } = allStaffSlice.actions;
export default allStaffSlice.reducer;
