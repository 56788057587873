import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
function BtnLoading() {
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Spinner
        width={"1rem"}
        thickness="2px"
        speed="0.8s"
        emptyColor="gray.200"
        color={btnColorScheme[theme]}
        size="sm"
        height={"1rem"}
      />
    </Flex>
  );
}

export default BtnLoading;
