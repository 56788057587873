import React, { useState } from "react";
import MainPage from "./pages/MainPage";
import Stats from "./pages/Stats";
import AddOrder from "./pages/AddOrder";
import AllUsers from "./pages/AllUsers";
import OrderPage from "./pages/OrderPage";
import Error from "./pages/Error";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllOrders from "./pages/AllOrders";
import Login from "./pages/Login";
import UpdateUserPage from "./pages/UpdateUserPage";
import AddOrderOption from "./pages/AddOrderOption";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Trash from "./pages/Trash";
import * as signalR from "@microsoft/signalr";
import { BASEURL } from "./utils/urls";
import Unauthorized from "./pages/results/403";
import Success from "./pages/results/success";
import Faild from "./pages/results/Faild";
import ServerError from "./pages/results/500";
import Chat from "./components/chat/Chat";
import { BiSolidMessageRounded } from "react-icons/bi";
import NewProduct from "./components/product/NewProduct";
import Cookies from "js-cookie";
import {
  changeStateInfo,
  changeStateInfoClinic,
  createInfo,
  updateInfo,
} from "./components/notification/createInfo";
import {
  addNotification,
  getNotfications,
} from "./features/notification/notificationSlice";

/////////////////
import { notification, message as myMessage, message } from "antd";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import "./index.css"; // Make sure to adjust the path if needed
import {
  addMessage,
  changeTyping,
  checkIfThiChatIsOpen2,
  unreadChats,
  updateMessages,
} from "./features/chat/chatSlice";

import { useDispatch, useSelector } from "react-redux";
import { getLanguageFromLocalStorage } from "./utils/LocalStorage";

import AddAd from "./pages/AddAd";
import { removeAd, updateAd } from "./features/Ad";
import { logOut } from "./features/user/UserSlice";
import ProductsStats from "./pages/ProductsStats";
import UpdateProduct from "./components/product/UpdateProduct";

import AccountantOrder from "./pages/accountant/AccountantOrder";

import AccountantStats from "./pages/accountant/AccountantStats";
function App() {
  const dispatch = useDispatch();
  const [api, notification1, contextHolder] = notification.useNotification();
  const { user, accountant } = useSelector((store) => store.user);

  const { states, error } = useSelector((store) => store.lang);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  const openNotification = (content, title, chatId, clinicId) => {
    api.info({
      message: (
        <Link to={"chat"} state={{ chatId, clinicId }}>
          <Button variant={"link"} colorScheme={"blue"}>
            {title}
          </Button>
        </Link>
      ),
      description: content,
      placement: "bottomRight",
      icon: <BiSolidMessageRounded color="#0088cc" />,
    });
  };

  ///// signal r//////////////
  const notifications = useSelector((store) => store.lang.notifications);

  useEffect(() => {
    Cookies.set("lang", getLanguageFromLocalStorage());
  }, [user]);

  const [connection, setConnection] = useState(null);
  const token = useSelector((store) => store.user?.user?.token);

  useEffect(() => {
    if (token === undefined) return;
    if (!user) return;
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        BASEURL + "/api/notification-hub",
        {
          headers: {
            Authorization: token,
          },
        },
        {
          accessTokenFactory: () => token,
        }
      )
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
    if (newConnection)
      newConnection
        .start()
        .then(() => {
          console.log("SignalR Connected");
        })
        .catch((err) => {
          if (err.message && err.message.includes("401")) {
            message.error(error.sessionExpired);
            dispatch(logOut());
          } else {
          }
        });
    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, [user]);

  useEffect(() => {
    if (connection) {
      connection.on("CreateOrder", (message) => {
        message.state = 0;
        createInfo(api, message, notifications, btnColorScheme[theme]);
        dispatch(addNotification(message));
      });
      connection.on("UpdateOrder", (message) => {
        message.state = 1;
        updateInfo(api, message, notifications, btnColorScheme[theme]);
        dispatch(addNotification(message));
      });
      connection.on("ChanageOrderStateClinic", (message) => {
        changeStateInfoClinic(
          api,
          message,
          notifications,
          states,
          btnColorScheme[theme]
        );
        dispatch(addNotification(message));
      });
      connection.on("ChanageOrderState", (message) => {
        changeStateInfo(
          api,
          message,
          notifications,
          states,
          btnColorScheme[theme]
        );
        dispatch(addNotification(message));
      });
      connection.on("Notifications", (message) => {
        dispatch(getNotfications(message));
      });
      connection.on("NotificationsClinic", (message) => {
        dispatch(getNotfications(message));
      });
      connection.on("Chats", (message) => {
        dispatch(unreadChats(message));
      });
      connection.on("message", (message) => {
        if (message.owner === 1) {
          dispatch(
            checkIfThiChatIsOpen2({
              open: openNotification,

              title: message.title,
              chatId: message.chatId,
              clinicId: message.clinicId,
              content: message.content,
            })
          );
          dispatch(addMessage(message));
        } else dispatch(addMessage(message));
      });

      connection.on("chating", (message) => {
        if (message.owner === 1 && message.type === 1 && message.isTyping) {
          dispatch(changeTyping({ state: true, chatId: message.chatId }));
        } else if (message.type === 1 && message.owner === 1) {
          dispatch(changeTyping({ state: false, chatId: message.chatId }));
        }

        if (message.type === 2) {
          if (message.owner === 1) {
            dispatch(updateMessages(message.chatId));
          }
        }
      });
      connection.on("Announcement", (content) => {
        dispatch(updateAd({ id: content.id, content: content.content }));
      });
      connection.on("AnnouncementDeleted", (content) => {
        dispatch(removeAd(content));
      });
    }
  }, [connection]);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <MainPage
              connection={connection}
              notification1={notification1}
              contextHolder={contextHolder}
            />
          }
        >
          <Route
            index
            element={
              user?.role === "Accountant" || accountant ? (
                <AccountantStats />
              ) : (
                <Stats />
              )
            }
          />
          <Route path="/stats" element={<Stats />} />
          <Route path="invoice-stats" element={<AccountantStats />} />
          <Route path="products-stats" element={<ProductsStats />} />,
          <Route path="add-order" element={<AddOrder />} />
          <Route path="all-users" element={<AllUsers />} />
          <Route path="all-orders" element={<AllOrders />} />
          {/* <Route path="accountantOrders" element={<AllDeliverdOrders />} /> */}
          <Route path="order-info" element={<OrderPage />} />
          <Route path="order-info-Acc" element={<AccountantOrder />} />
          <Route path="chat" element={<Chat connection={connection} />} />
          <Route path="product" element={<NewProduct />} />
          <Route path="update-product" element={<UpdateProduct />} />
          <Route path="Trash" element={<Trash />} />
          <Route path="update-user" element={<UpdateUserPage />} />
          <Route path="add-option" element={<AddOrderOption />} />
          <Route path="addAd" element={<AddAd />} />,
          <Route path="success" element={<Success />} />
          <Route path="403" element={<Unauthorized />} />
          <Route path="faild" element={<Faild />} />
          <Route path="500" element={<ServerError />} />
        </Route>

        <Route path="register" element={<Login />} />

        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer position="bottom-center" />
    </Router>
  );
}

export default App;
